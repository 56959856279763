import * as React from 'react'
import { closePlaybookSubmissionModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalMiddle from './Parts/ModalMiddle'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import ReactTooltip from 'react-tooltip'
import { Call, CallOutcome, ContentBlock, ContentBlockType, CurrentUser, LedgerItem, Playbook, PlaybookableType, PlaybookSubmission, QuestionAndAnswerBlock, Task } from '../../types'
import PlaybookEditor from '../Playbook/PlaybookEditor'
import { ContactsController, PlaybookSubmissionsController, TasksController } from '../../controllers'
import ModalWindow from './Parts/ModalWindow'
import Icon from '../Icons/Icon'
import Button from '../Button/Button'
import ModalActionFollowUp from './Parts/ModalActionFollowUp'
import Popover from '../Popover/Popover'
import TaskPopoverContainer from '../TaskModal/TaskPopoverContainer'
import ActionList, { IActionListItem } from '../ActionList/ActionList'
import Dropdown from '../Form/Dropdown'
import DropdownButton from '../Form/DropdownButton'
import CallHelper from '../../helpers/CallHelper'
import moment from '../../utilities/Moment'
import CallsController from '../../controllers/CallsController'

const ModalWindowContainer = styled(ModalWindow)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 900px;
  min-height: 500px;
  max-height: 700px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    min-width: initial;
    max-height: 100vh;
    min-height: initial;
    max-width: initial;
    height: 100vh;
  }
`

const PlaybookSubmissionModalMiddle = styled(ModalMiddle)`
  flex: 1;
  height: 100%;
  max-height: 580px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-height: calc(100vh - 117px);
  }
`

const PlaybookSubmissionModalContent = styled(ModalContent)`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0;
  max-height: calc(100vh - 240px);
  height: 100%;
  width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-height: calc(100vh - 120px);
  }
`

const TableOfContent = styled.div`
  background: rgb(245, 248, 250);
  border-right: 1px solid ${Style.color.border};
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  overflow-y: auto;

  hr {
    margin: 0 ${Style.spacing.x2};
  }

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: none;
  }
`

const TableOfContentHeader = styled.div`
  padding: ${Style.spacing.x3} ${Style.spacing.x3} 0px;
  font-size: 22px;
  font-weight: 500;
  padding-bottom: ${Style.spacing.x2};
`

const TableContentItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Style.spacing.x3};
`

const TableContentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(124, 152, 182);
  font-weight: bold;
  width: 100%;
`

const TableContentItemIcon = styled.div`
  margin-right: ${Style.spacing.x1};
`

const TableContentItemDescription = styled.div`
  cursor: pointer;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`

const PlaybookContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 80px;
  background-color: white;
`

const Content = styled.div`
  flex: 1;
  overflow: hidden;
`

const LogCallContainer = styled.div`
 display: flex;
 flex-direction: row;
 align-items: center;
 gap: 8px;

 ${Dropdown} {
  cursor: pointer;
 }
`

const ModalActionFollowUpContainer = styled.div<{ showOnMobile?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: none;

    ${({ showOnMobile }) => showOnMobile && css`
      display: flex;
    `}
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  playbookSubmission: PlaybookSubmission
  onSubmit?: (playbookSubmission: PlaybookSubmission, call?: Call, task?: Task) => void
}

interface IDispatchToProps {
  close: typeof closePlaybookSubmissionModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  playbook: Playbook
  playbookSubmission: PlaybookSubmission
  errors: any
  task: Task
  isSubmitting: boolean
  callOutcomePopoverActive: boolean
  callOutcome: CallOutcome
}

class PlaybookSubmissionModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      playbook: null,
      playbookSubmission: null,
      errors: {},
      task: null,
      isSubmitting: false,
      callOutcomePopoverActive: false,
      callOutcome: null,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
    this.onQuestionClick = this.onQuestionClick.bind(this)
    this.onQuestionAnswerChange = this.onQuestionAnswerChange.bind(this)
    this.onCallOutcomePopoverClose = this.onCallOutcomePopoverClose.bind(this)
    this.onCallOutcomePopoverToggle = this.onCallOutcomePopoverToggle.bind(this)
    this.onFollowUpActionChange = this.onFollowUpActionChange.bind(this)
    this.onOutcomeSelect = this.onOutcomeSelect.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  async fetchForm() {
    const { playbookSubmission } = this.props

    try {
      const { playbook, playbook_submission } = await PlaybookSubmissionsController.getForm({
        playbook_id: playbookSubmission.playbook_id,
        id: playbookSubmission.id,
        playbookable_type: playbookSubmission.playbookable_type,
        playbookable_id: playbookSubmission.playbookable_id,
      })

      this.setState({
        didInitialLoad: true,
        playbook: playbook,
        playbookSubmission: playbook_submission,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { onSubmit } = this.props
    const { playbookSubmission, callOutcome, task } = this.state

    let loggedCall = null
    let loggedTask = null

    try {
      this.setState({ isSubmitting: true })


      let loggedCall: Call = {
        datetime: moment().toISOString(),
        contact_id: playbookSubmission.playbookable_id,
      }
      if (callOutcome) loggedCall = { ...loggedCall, outcome: callOutcome }

      switch (playbookSubmission.playbookable_type) {
        case PlaybookableType.CONTACT:
          loggedCall = await CallsController.create(loggedCall).catch(console.error)
          break
      }

      if (task) {
        let followUpActionTask: Task = task

        switch (playbookSubmission.playbookable_type) {
          case PlaybookableType.CONTACT:
            const contact = await ContactsController.getContact(playbookSubmission.playbookable_id)
            followUpActionTask = {
              ...followUpActionTask,
              name: `${followUpActionTask.name} ${contact.name}`,
              contact_id: playbookSubmission.playbookable_id
            }
            break
          case PlaybookableType.TASK:
            const playbookableTask: Task = await TasksController.getTask(playbookSubmission.playbookable_id)

            followUpActionTask = {
              ...followUpActionTask,
              name: `${followUpActionTask.name} ${playbookableTask?.contact?.name}`,
              contact_id: playbookableTask.contact_id ? playbookableTask.contact_id : null,
              telephone_number: playbookableTask.telephone_number ? playbookableTask.telephone_number : null,
            }
            break
        }

        // Create follow up action
        loggedTask = await TasksController.create(followUpActionTask)
      }

      if (onSubmit) onSubmit(playbookSubmission, loggedCall, loggedTask)

      // Close modal
      this.props.close()
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isSubmitting: false })
    }
  }

  onCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onQuestionClick(block: ContentBlock) {
    document.getElementById(block.id).scrollIntoView();
  }

  async onQuestionAnswerChange(block: QuestionAndAnswerBlock) {
    const { playbookSubmission } = this.state

    try {
      let updatedPlaybookSubmission: PlaybookSubmission = {
        ...playbookSubmission,
        answers: [...playbookSubmission.answers]
      }

      const answerIndex = updatedPlaybookSubmission.answers.findIndex((answer) => answer.question_id === block.id)

      if (answerIndex === -1) {
        updatedPlaybookSubmission.answers.push({
          question_id: block.id,
          notes: Array.isArray(block.answer) ? "" : block.answer,
          selected_answers: Array.isArray(block.answer) ? block.answer : [],
        })
      } else {
        updatedPlaybookSubmission.answers[answerIndex] = {
          ...updatedPlaybookSubmission.answers[answerIndex],
          notes: Array.isArray(block.answer) ? "" : block.answer,
          selected_answers: Array.isArray(block.answer) ? block.answer : [],
        }
      }

      if (playbookSubmission.id) {
        updatedPlaybookSubmission = await PlaybookSubmissionsController.update({ ...updatedPlaybookSubmission })
      } else {
        updatedPlaybookSubmission = await PlaybookSubmissionsController.create({ ...updatedPlaybookSubmission })
      }

      this.setState({ playbookSubmission: { ...updatedPlaybookSubmission } })
    } catch (ex) {
      console.error(ex)
    }
  }

  onCallOutcomePopoverClose() {
    this.setState({ callOutcomePopoverActive: false })
  }

  onCallOutcomePopoverToggle(e) {
    e.preventDefault()
    e.stopPropagation()
    const { callOutcomePopoverActive } = this.state
    this.setState({ callOutcomePopoverActive: !callOutcomePopoverActive })
  }

  onFollowUpActionChange(task: Task) {
    this.setState({ task: task })
  }

  getCallOutcomeLabel() {
    const { t } = this.props
    const { callOutcome } = this.state

    if (callOutcome) return CallHelper.getOutcomeLabel(callOutcome)

    return t('PlaybookSubmissionModal::Select an outcome')
  }

  onOutcomeSelect(outcome: CallOutcome) {
    this.setState({
      callOutcome: outcome,
      callOutcomePopoverActive: false
    })
  }

  render() {
    const { t, currentUser } = this.props
    const {
      didInitialLoad,
      playbook,
      playbookSubmission,
      callOutcomePopoverActive,
      isSubmitting
    } = this.state

    const outcomeActions: IActionListItem[] = CallHelper.getOutcomeOptions().map((option) => {
      return {
        key: option.value,
        content: option.label,
        onClick: () => this.onOutcomeSelect(option.value as CallOutcome)
      }
    })

    return (
      <ModalWindowContainer>
        <Content>
          <ModalHeader
            title={playbook ? playbook.name : t('PlaybookSubmissionModal::Playbook')}
            onCloseClick={this.onCloseClick}
          />

          {!didInitialLoad && <ModalLoader />}
          {didInitialLoad && <PlaybookSubmissionModalMiddle>
            <PlaybookSubmissionModalContent>
              <TableOfContent>
                <TableOfContentHeader>
                  {t('PlaybookSubmissionModal::Playbook contents')}
                </TableOfContentHeader>

                <hr />

                <TableContentItems>
                  {playbook.content_blocks
                    .filter((contentBlock) => contentBlock.type === ContentBlockType.QUESTION_AND_ANSWER)
                    .map(contentBlock => {
                      const description = contentBlock.type === ContentBlockType.QUESTION_AND_ANSWER && contentBlock.title
                      return (
                        <TableContentItem key={contentBlock.id} onClick={() => this.onQuestionClick(contentBlock)}>
                          <TableContentItemIcon>
                            <Icon icon='question-circle' />
                          </TableContentItemIcon>

                          <TableContentItemDescription data-tip={description}>
                            {description}
                          </TableContentItemDescription>
                        </TableContentItem>
                      )
                    })}
                </TableContentItems>
              </TableOfContent>
              <PlaybookContent>
                <PlaybookEditor
                  playbook={playbook}
                  playbookSubmission={playbookSubmission}
                  setting={currentUser.workspace.setting}
                  onAnswerChange={this.onQuestionAnswerChange}
                />
              </PlaybookContent>
            </PlaybookSubmissionModalContent>
          </PlaybookSubmissionModalMiddle>}


          <div className='modal-footer'>
            <div className='modal-footer-actions'>
              {playbookSubmission?.playbookable_type === PlaybookableType.CONTACT && <div key='main-action' className='popover-wrapper'>
                <LogCallContainer>
                  <Button
                    type='success'
                    text={t('PlaybookSubmissionModal::Log call')}
                    onClick={this.onFormSubmit}
                    isLoading={isSubmitting}
                  />

                  <Popover
                    activator={
                      <Dropdown onClick={this.onCallOutcomePopoverToggle}>
                        {this.getCallOutcomeLabel()} <DropdownButton />
                      </Dropdown>
                    }
                    onClose={this.onCallOutcomePopoverClose}
                    active={callOutcomePopoverActive}
                    placement='top'
                  >
                    <TaskPopoverContainer style={{ maxWidth: 144 }}>
                      <ActionList actions={outcomeActions} />
                    </TaskPopoverContainer>
                  </Popover>
                </LogCallContainer>
              </div>}

              <ModalActionFollowUpContainer showOnMobile={playbookSubmission?.playbookable_type === PlaybookableType.TASK}>
                {playbookSubmission?.playbookable_type === PlaybookableType.TASK && <Button
                  type='success'
                  text={t('PlaybookSubmissionModal::Save')}
                  onClick={this.onFormSubmit}
                  isLoading={isSubmitting}
                />}
                <ModalActionFollowUp onFollowUpActionChange={this.onFollowUpActionChange} />
              </ModalActionFollowUpContainer>
            </div>
            <div />
          </div>
        </Content>
      </ModalWindowContainer>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      playbookSubmissionModal: {
        playbookSubmission,
        onSubmit
      }
    }
  } = state

  return {
    playbookSubmission: playbookSubmission,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closePlaybookSubmissionModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PlaybookSubmissionModal))