import * as React from 'react'
import { closeWorkTypeModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Button from '../Button/Button'
import { WorkTypesController } from '../../controllers'
import { CurrentUser, DisplayableError, WorkType } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'
import MoneyInput from '../Form/MoneyInput'
import CheckboxInput from '../Form/CheckboxInput'
import NumberFormatter from '../../utilities/NumberFormatter'

interface IStateToProps {
  currentUser: CurrentUser
  workType?: WorkType
  onSubmit: (workType: WorkType) => void
}

interface IDispatchToProps {
  close: typeof closeWorkTypeModal
}

type IProps = IDispatchToProps & IStateToProps

interface IState {
  didInitialLoad: boolean
  workType: WorkType
  errors: DisplayableError[]
}

const WorkTypeModal = (props: IProps) => {
  const { t } = useTranslation()
  const { currentUser } = props
  const { workspace: { setting } } = currentUser
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    workType: null,
    errors: [],
  })
  const { didInitialLoad, workType, errors } = state

  React.useEffect(() => {
    getForm().catch(console.error)
  }, [])

  const getForm = async () => {
    try {
      const { work_type } = await WorkTypesController.getForm({ id: props.workType ? props.workType.id : null })

      setState({
        ...state,
        workType: {
          ...work_type,
          ...props.workType,
        },
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onNameChange = (e) => {
    const newName = e.currentTarget.value

    setState({ ...state, workType: { ...workType, name: newName } })
  }

  const onBillableChange = () => {
    const newBillable = !workType.billable

    let hourlyRate = newBillable ? workType.hourly_rate : 0
    let dayRate = newBillable ? workType.day_rate : 0

    setState({
      ...state,
      workType: {
        ...workType,
        billable: !workType.billable,
        hourly_rate: hourlyRate,
        day_rate: dayRate,
      }
    })
  }

  const onHourlyRateChange = (hourlyRate) => {
    setState({ ...state, workType: { ...workType, hourly_rate: hourlyRate } })
  }

  const onDayRateChange = (dayRate) => {
    setState({ ...state, workType: { ...workType, day_rate: dayRate } })
  }

  const onCloseClick = () => {
    props.close()
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
      let response = null
      if (workType.id) {
        response = await WorkTypesController.update(workType)
      } else {
        response = await WorkTypesController.create(workType)
      }

      if (response.errors) {
        setState({ ...state, errors: response.errors })
      } else {
        props.onSubmit(response)
        props.close()
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  const onErrorsDismiss = () => {
    setState({ ...state, errors: [] })
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={workType?.id ? t('WorkTypeModal::Update work type') : t('WorkTypeModal::Create work type')}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            <div className='grid'>
              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>{t('WorkTypeModal::Name')} <span>*</span></label>
                  <input
                    type='text'
                    placeholder={t('WorkTypeModal::Name')}
                    onChange={onNameChange}
                    value={workType.name}
                  />
                </div>
              </div>

              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>{t('WorkTypeModal::Billable')}</label>
                  <CheckboxInput
                    onChange={onBillableChange}
                    checked={workType.billable}
                    label={t('WorkTypeModal::Billable')}
                  />
                </div>
              </div>

              {workType.billable && <>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('WorkTypeModal::Hourly rate')}</label>
                    <MoneyInput
                      name='hourly_rate'
                      currency={setting.default_currency}
                      numberFormat={setting.number_format}
                      placeholderValue={setting.default_hourly_rate}
                      value={workType?.hourly_rate}
                      onBlur={onHourlyRateChange}
                      optional
                    />
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('WorkTypeModal::Day rate')}</label>
                    <MoneyInput
                      name='day_rate'
                      currency={setting.default_currency}
                      numberFormat={setting.number_format}
                      placeholderValue={setting.default_day_rate}
                      value={workType.day_rate}
                      onBlur={onDayRateChange}
                      optional
                    />
                  </div>
                </div>
              </>}
            </div>

            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <div />
          <div key='main-action' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={onErrorsDismiss}
            />
            <Button
              type='success'
              text={workType?.id ? t('WorkTypeModal::Update work type') : t('WorkTypeModal::Create work type')}
              onClick={onFormSubmit}
            />
          </div>
        </div>
      </ModalMiddle>}
    </ModalWindow >
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      workTypeModal: {
        workType,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    workType: workType,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeWorkTypeModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkTypeModal)