import * as React from 'react'
import { closePaymentInitiationModal, showConfirmModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Button from '../Button/Button'
import { PaymentInitiationsController } from '../../controllers'
import { CurrentUser, DisplayableError, RemittanceInformationType, PaymentInitiation, PaymentInitiationIntegrationMetadata, PaymentInitiationStatus } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'
import OgmHelper from '../../helpers/OgmHelper'
import ResourceModalTable from '../Resource/Modal/ResourceModalTable'
import ResourceModalTableRow from '../Resource/Modal/ResourceModalTableRow'
import ResourceModalTableRowHeader from '../Resource/Modal/ResourceModalTableRowHeader'
import ResourceModalTableRowData from '../Resource/Modal/ResourceModalTableRowData'
import NumberFormatter from '../../utilities/NumberFormatter'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'
import ModalFooterActionIcons from './Parts/ModalFooterActionIcons'
import ModalFooterActionIcon from './Parts/ModalFooterAction'
import Images from '../../images'
import { useHistory } from 'react-router-dom'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'
import ModalDescription from './Parts/ModalDescription'
import Tooltip from '../Tooltips/Tooltip'
import InputAlert from '../Form/InputAlert'
import Alert from '../Alert/Alert'
import PaymentHelper from '../../helpers/PaymentHelper'
import { Style } from '../../styles'

interface IStateToProps {
  currentUser: CurrentUser
  paymentInitiation?: PaymentInitiation
  onSubmit: (paymentInitiation: PaymentInitiation) => void
  onDelete: (paymentInitiation: PaymentInitiation) => void
}

interface IDispatchToProps {
  close: typeof closePaymentInitiationModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IDispatchToProps & IStateToProps

interface IState {
  didInitialLoad: boolean
  paymentInitiation: PaymentInitiation
  paymentInitiationIntegrationMetadata: PaymentInitiationIntegrationMetadata
  errors: DisplayableError[]
}

const PaymentInitiationModal = (props: IProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { currentUser } = props
  const { setting } = currentUser.workspace
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    paymentInitiation: null,
    paymentInitiationIntegrationMetadata: null,
    errors: [],
  })
  const { didInitialLoad, paymentInitiation, paymentInitiationIntegrationMetadata, errors } = state
  const paymentsActivated = paymentInitiationIntegrationMetadata?.payments_activated && paymentInitiationIntegrationMetadata?.payments_activation_requested
  const suggestedExecutionDate = PaymentHelper.getSuggestedExecutionDateForPayments(paymentInitiation?.payments || [])

  React.useEffect(() => {
    getForm().catch(console.error)
  }, [])

  const getForm = async () => {
    try {
      const { payment_initiation, payment_initiation_metadata } = await PaymentInitiationsController.getForm({ id: props.paymentInitiation ? props.paymentInitiation.id : null })

      if (!payment_initiation.id && props.paymentInitiation.payments) {
        payment_initiation.payments = [...props.paymentInitiation.payments]
      }

      setState({
        ...state,
        paymentInitiation: payment_initiation,
        paymentInitiationIntegrationMetadata: payment_initiation_metadata,
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  const getTitle = () => {
    if (paymentInitiationIntegrationMetadata?.integration_enabled === false) return t('PaymentInitiationModal::Integration required')
    if (paymentInitiationIntegrationMetadata?.payments_activated === false || paymentInitiationIntegrationMetadata?.payments_activation_requested === false) return t('PaymentInitiationModal::Payment activation required')
    return paymentInitiation?.id ? t('PaymentInitiationModal::Payment information') : t('PaymentInitiationModal::Schedule payment')
  }

  const onReferenceChange = (e) => {
    const newName = e.currentTarget.value

    setState({ ...state, paymentInitiation: { ...paymentInitiation, reference: newName } })
  }

  const onExecutionDateChange = (value) => {
    const executionDate = moment(value);

    setState({
      ...state,
      paymentInitiation: {
        ...paymentInitiation,
        execution_date: executionDate.isValid() ? executionDate.format('YYYY-MM-DD') : null
      }
    })
  }

  const onNotesChange = (e) => {
    const notes = e.currentTarget.value

    setState({ ...state, paymentInitiation: { ...paymentInitiation, notes: notes } })
  }

  const onApplySuggestedExecutionDateClick = () => {
    if (suggestedExecutionDate) {
      const suggestedExecutionDateMoment = moment(suggestedExecutionDate)

      setState({
        ...state,
        paymentInitiation: {
          ...paymentInitiation,
          execution_date: suggestedExecutionDateMoment.isValid() ? suggestedExecutionDateMoment.format('YYYY-MM-DD') : null
        }
      })
    }
  }

  const onCloseClick = () => {
    props.close()
  }

  const onSignPaymentClick = () => {
    window.location.assign(paymentInitiation.sign_redirect_url)
  }

  const onActivatePaymentsClick = async () => {
    try {
      if (!paymentInitiationIntegrationMetadata?.integration_enabled) {
        history.replace(RouteHelper.process(ERoute.PATH_INTEGRATION, { type: 'ponto' }))
        props.close()
      } else {
        const response = await PaymentInitiationsController.activate()

        if (response.errors) {
          setState({ ...state, errors: response.errors })
        } else if (response.redirect_url) {
          window.location.assign(response.redirect_url)
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
      let response = null
      if (paymentInitiation.id) {
        response = await PaymentInitiationsController.update(paymentInitiation)
      } else {
        response = await PaymentInitiationsController.create(paymentInitiation, paymentInitiation?.payments.map(payment => payment.id))
      }

      if (response.errors) {
        setState({ ...state, errors: response.errors })
      } else {
        if (!paymentInitiation.id) {
          const createdPaymentInitation: PaymentInitiation = response
          window.location.assign(createdPaymentInitation.sign_redirect_url)
        } else {
          if (props.onSubmit) props.onSubmit(response)
          props.close()
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const onDeletePaymentClick = () => {
    props.showConfirmModal({
      title: t('PaymentInitiationModal::Delete scheduled payment'),
      description: t('PaymentInitiationModal::You are about to delete this scheduled payment. By deleting this payment your scheduled payment will be stopped and removed from our systems. Are you sure?'),
      action: {
        label: t('PaymentInitiationModal::Delete'),
        isDestructive: true,
      },
      onConfirm: async () => {
        try {
          await PaymentInitiationsController.delete(paymentInitiation.id)
          if (props.onDelete) props.onDelete(paymentInitiation)
          props.close()
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  const onErrorsDismiss = () => {
    setState({ ...state, errors: [] })
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={getTitle()}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          {!paymentsActivated && <>
            {paymentInitiationIntegrationMetadata?.integration_enabled === false && <ModalDescription>
              {t('PaymentInitiationModal::Enable our payment integration to pay your invoices securely in bulk with a single signature.')}
            </ModalDescription>}
            {paymentInitiationIntegrationMetadata?.integration_enabled && <img src={Images.ACTIVATE} style={{ maxHeight: 335 }} />}
          </>}
          {paymentsActivated && <>
            <form onSubmit={onFormSubmit}>
              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('PaymentInitiationModal::Reference')} <span>*</span>
                      <Tooltip
                        content={t('PaymentInitiationModal::Provide an internal reference to this payment for your own records')}
                        containerStyle={{ marginLeft: 8 }}
                      />
                    </label>
                    <input
                      type='text'
                      value={paymentInitiation.reference}
                      onChange={onReferenceChange}
                      placeholder={t('PaymentInitiationModal::Reference')}
                      disabled={Boolean(paymentInitiation.id)}
                    />
                  </div>
                </div>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('PaymentInitiationModal::Execution date')} <span>*</span></label>
                    {paymentInitiation.id && <input type='text' value={moment(paymentInitiation.execution_date).format(setting.date_format)} disabled={true} />}
                    {!paymentInitiation.id && <DateInput
                      name='execution_date'
                      dateFormat={setting.date_format}
                      inputProps={{ placeholder: setting.date_format }}
                      timeFormat={false}
                      value={moment(paymentInitiation.execution_date)}
                      onChange={onExecutionDateChange}
                      closeOnSelect
                    />}

                    {suggestedExecutionDate && paymentInitiation?.status !== PaymentInitiationStatus.SIGNED && !moment(suggestedExecutionDate).isSame(moment(paymentInitiation.execution_date)) && <div style={{ marginTop: Style.spacing.x1 }}>
                      <Alert
                        type='warning'
                        text={<InputAlert>
                          <div>
                            <Tooltip
                              content={t("PaymentInitiationModal::We've determined the ideal execution date to pay your selected payments.")}
                              containerStyle={{ marginRight: 8 }}
                            />
                            {moment(suggestedExecutionDate).format(setting.date_format)}
                          </div>

                          <a href='javascript://' onClick={onApplySuggestedExecutionDateClick}>
                            {`${t('PaymentInitiationModal::Apply')}`}
                          </a>
                        </InputAlert>}
                      />
                    </div>}
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('PaymentInitiationModal::Notes')}</label>
                    <textarea
                      placeholder={t('PaymentInitiationModal::Add notes (only visible to you)')}
                      onChange={onNotesChange}
                      value={paymentInitiation.notes}
                      disabled={Boolean(paymentInitiation.id)}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  {paymentInitiation?.payments.length > 0 && <ResourceModalTable>
                    <ResourceModalTableRow header={true}>
                      <ResourceModalTableRowHeader>
                        {t('PaymentInitiationModal::Account')}
                      </ResourceModalTableRowHeader>
                      <ResourceModalTableRowHeader>
                        {t('PaymentInitiationModal::Remittance information')}
                      </ResourceModalTableRowHeader>
                      <ResourceModalTableRowHeader>
                        {t('PaymentInitiationModal::Amount')}
                      </ResourceModalTableRowHeader>
                    </ResourceModalTableRow>

                    {paymentInitiation?.payments.map((payment, index) => (
                      <ResourceModalTableRow key={payment.id}>
                        <ResourceModalTableRowData>
                          {payment.creditor_name}
                        </ResourceModalTableRowData>
                        <ResourceModalTableRowData>
                          {payment.remittance_information.length === 0 && '-'}
                          {payment.remittance_information.length > 0 && payment.remittance_information_type === RemittanceInformationType.STRUCTURED ? OgmHelper.format(payment.remittance_information) : payment.remittance_information}
                        </ResourceModalTableRowData>
                        <ResourceModalTableRowData>
                          {NumberFormatter.formatCurrency(payment.currency, setting.number_format, payment.amount)}
                        </ResourceModalTableRowData>
                      </ResourceModalTableRow>
                    ))}
                  </ResourceModalTable>}
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
            </form>
          </>}
        </ModalContent>

        <div className='modal-footer'>
          {!paymentsActivated && <>
            <div></div>
            <div>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={onErrorsDismiss}
                />
                <Button
                  type='success'
                  text={paymentInitiationIntegrationMetadata?.integration_enabled ? t('PaymentInitiationModal::Activate payments') : t('PaymentInitiationModal::Enable integration')}
                  onClick={onActivatePaymentsClick}
                />
              </div>
            </div>
          </>}
          {paymentsActivated && <>
            <ModalFooterActionIcons>
              {paymentInitiation && paymentInitiation.id && paymentInitiation.status !== PaymentInitiationStatus.SIGNED && <ModalFooterActionIcon
                icon='trash'
                active={false}
                onClick={onDeletePaymentClick}
                tooltip={t('PaymentInitiationModal::Delete payment')}
              />}
            </ModalFooterActionIcons>
            <div>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={onErrorsDismiss}
                />
                {paymentInitiation && paymentInitiation.id && [PaymentInitiationStatus.UNSIGNED].includes(paymentInitiation.status) && <>
                  <Button
                    type='success'
                    text={t('PaymentInitiationModal::Sign payment')}
                    onClick={onSignPaymentClick}
                  />
                </>}
                {paymentInitiation && !paymentInitiation.id && <Button
                  type='success'
                  text={t('PaymentInitiationModal::Sign payment')}
                  onClick={onFormSubmit}
                />}
              </div>
            </div>
          </>}
        </div>
      </ModalMiddle>}
    </ModalWindow >
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      paymentInitiationModal: {
        paymentInitiation,
        onSubmit,
        onDelete,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    paymentInitiation: paymentInitiation,
    onSubmit: onSubmit,
    onDelete: onDelete
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closePaymentInitiationModal()),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInitiationModal)