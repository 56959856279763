import * as React from 'react'
import TimeFormatter from '../../utilities/TimeFormatter';
import parse from 'parse-duration';
import { WithTranslation, withTranslation } from 'react-i18next';

type IProps = {
	value: string | number
	format?: parse.Units
	onChange?: (value: number) => void
} & WithTranslation

class DurationInput extends React.PureComponent<IProps> {
	private input = React.createRef<HTMLInputElement>()

	constructor(props) {
		super(props);

		this.onBlur = this.onBlur.bind(this)
		this.onChange = this.onChange.bind(this)
	}

	getInitialValue() {
		const { value } = this.props
		const parsedValue = Number(value)

		return parsedValue > 0 ? TimeFormatter.formatToHumanReadableDuration(parsedValue) : ''
	}

	onBlur(e) {
		const { onChange } = this.props
		const parsedDuration = TimeFormatter.parseDuration(e.currentTarget.value)

		if (this.input.current) {
			this.input.current.value = parsedDuration && parsedDuration > 0 ? TimeFormatter.formatToHumanReadableDuration(parsedDuration) : ''
		}

		onChange(parsedDuration)
	}

	onChange(e) {
		const { onChange } = this.props
		const parsedDuration = TimeFormatter.parseDuration(e.currentTarget.value)

		onChange(parsedDuration)
	}

	render() {
		const {
			t,
			value,
		} = this.props;

		return (
			<input
				ref={this.input}
				type='text'
				autoComplete='off'
				onBlur={this.onBlur}
				onChange={this.onChange}
				defaultValue={this.getInitialValue()}
				placeholder={t('DurationInput::1 day / 1h30m / 1:30')}
			/>
		)
	}
}

export default withTranslation()(DurationInput)