import * as React from 'react'
import Datetime from 'react-datetime'
import { closeBulkTimeEntryModal } from '../../store/modals/actions'
import MaskedInput from 'react-text-mask';
import ModalWindowPart from './Parts/ModalWindow'
import { AppState } from '../../store';
import { connect } from 'react-redux';
import styled from 'styled-components'
import { Style } from '../../styles'
import { WithTranslation, withTranslation } from 'react-i18next';
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect';
import moment from '../../utilities/Moment';
import Icon from '../Icons/Icon';
import TooltipError from '../Tooltips/ErrorTooltip';
import ModalHeader from './Parts/ModalHeader';
import { Moment } from 'moment';
import TimeEntryHelper from '../../helpers/TimeEntryHelper';
import TimeFormatter from '../../utilities/TimeFormatter';
import { TimeEntriesController } from '../../controllers';
import Button from '../Button/Button';
import ModalLoader from './Parts/ModalLoader';
import Popover from '../Popover/Popover';
import InputGroup from '../Forms/InputGroup';
import { Contact, CurrentUser, Project, ProjectStatus, TimeEntry, WorkType } from '../../types';
import CheckboxInput from '../Form/CheckboxInput';

const ModalWindow = styled(ModalWindowPart)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 986px;
  min-height: 500px;
  max-height: 700px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    min-width: initial;
    max-height: initial;
    min-height: initial;
    max-width: initial;
  }
`

const ModalSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${Style.spacing.x1};

  > span {
    margin-right: 20px;
    text-transform: uppercase;
    font-size: 10px;
    color: #777;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 1;
    margin-bottom: 6px;
  }
`

const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 31px;
`

const TableRowLeft = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid transparent;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #d8d8d8;

  &:last-child {
    border-bottom-color: transparent;
  }
`

const TableRowLeftGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > ${FormItem}:not(:last-child) {
    margin-right: ${Style.spacing.x1};
  }
`

const DeleteRowButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${Style.color.brandDanger};

  svg {
    width: 14px;
    margin-right: ${Style.spacing.x1};
  }
`


const AddRowButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 24px 24px;
  color: ${Style.color.brandSuccess};

  i {
    margin-right: ${Style.spacing.x1};
  }
`

const ModalHeaderDateNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 14px;
`

const ModalHeaderNavigate = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: 30px;

  &:hover {
    background: #fafafa;
    border-radius: 50%;
  }
`

const ModalHeaderDate = styled.div`
  font-size: 22px;
  padding: 0 10px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  flex: 1;
`

const ModalContent = styled.form`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
  max-width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-height: 100%;
  }
`

const ModalContentLeft = styled.div`
  flex: 1;
  background: #fafafa;
  height: fit-content;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    min-width: 500px;
  }

`

const ModalContentRight = styled.div`
  padding-top: 30px;
  height: fit-content;
`

const ModalContentRightHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
`

const ModalTableHeader = styled.div`
  display: flex;
  align-items: flex-end;
  height: 38px;
`

const ModalTableHeaderRight = styled.div`
  display: flex;
  padding-bottom: ${Style.spacing.x1};
`

const DayHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  margin-right: ${Style.spacing.x1_25};
  color: #383838;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1;
  padding-bottom: 4px;

  &:last-child {
    margin-right: 0;
  }
`

const DayHeaderWeekday = styled.div`
  text-transform: uppercase;
  color: #777;
  padding-bottom: 7px;
`
const DayHeaderMonthDay = styled.div`
  font-size: 12px;
  font-weight: 500;
`
const DayHeaderHours = styled.div`
  font-size: 12px;
  font-weight: 400;
`

const ModalTableContent = styled.div`
  display: flex;
  flex-direction: column;
`

const TableRowRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid transparent;
  padding: 24px;
  height: 322px;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;

  &:first-child {
    height: calc(322px - 14px);
  }
`

const HourInputContainer = styled.div`
  width: 60px;
  margin-right: 10px;

  input {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
`
const TotalHoursLabel = styled.div`
  font-size: 17px;
  color: black;
  font-weight: 700;
`

const CalendarContainer = styled.div`
  .rdtDays {
    border: 1px solid ${Style.color.border};
    border-radius: ${Style.variables.baseBorderRadius};
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  onSubmit?: (timeEntries: TimeEntry[]) => void
}

interface IDispatchToProps {
  close: typeof closeBulkTimeEntryModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  hasWorkTypes: boolean
  calendarActive: boolean
  startDate: Moment
  endDate: Moment
  rowCount: number
  userId: string
  entries: {
    [key: string]: {
      [key: string]: TimeEntry & {
        // Add entries
        entries?: {
          [key: string]: TimeEntry
        }
      }
    }
  }
  errors: any
  isSubmitting: boolean
}

class BulkTimeEntryModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      hasWorkTypes: false,
      calendarActive: false,
      userId: props.currentUser.id,
      startDate: moment().startOf('week'),
      endDate: moment().endOf('week'),
      rowCount: 2,
      entries: {},
      errors: {},
      isSubmitting: false
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onModalCloseClick = this.onModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onNavigateBackward = this.onNavigateBackward.bind(this)
    this.onNavigateForward = this.onNavigateForward.bind(this)
    this.onNavigationHeaderClick = this.onNavigationHeaderClick.bind(this)
    this.onDatePopoverClose = this.onDatePopoverClose.bind(this)
    this.onRangeChange = this.onRangeChange.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onBillableChange = this.onBillableChange.bind(this)
    this.onDayRateChange = this.onDayRateChange.bind(this)
    this.onContactChange = this.onContactChange.bind(this)
    this.onProjectChange = this.onProjectChange.bind(this)
    this.onHourChange = this.onHourChange.bind(this)
    this.onHourBlur = this.onHourBlur.bind(this)
    this.onDeleteRowClick = this.onDeleteRowClick.bind(this)
    this.onAddRowClick = this.onAddRowClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    try {
      const { work_type } = await TimeEntriesController.getForm()

      requestAnimationFrame(() => {
        this.setState({
          didInitialLoad: true,
          hasWorkTypes: Boolean(work_type),
        });
      })
    }
    catch (ex) {
      console.error(ex)
    }
  }

  getRangeKey() {
    const { startDate, endDate } = this.state
    return `${startDate.format('YYYY-MM-DD')}|${endDate.format('YYYY-MM-DD')}`
  }

  getRows() {
    const { rowCount } = this.state
    return Array.from(Array(rowCount).keys())
  }

  getRowKey(startDate: Moment, endDate: Moment, rowIndex: number) {
    return `${startDate.format('YYYY-MM-DD')}-${endDate.format('YYYY-MM-DD')}-${rowIndex}`
  }

  getSelectedRange(): Moment[] {
    const { startDate, endDate } = this.state

    // @ts-ignore
    return Array.from(moment.range(startDate, endDate).by('days'))
  }

  getRefKey(row: number, column: number) {
    return `${this.getRangeKey()}-${row}-${column}}`
  }

  getNewTimeEntry(): TimeEntry {
    const { userId } = this.state
    return {
      contact_id: null,
      project_id: null,
      started_at: null,
      ended_at: null,
      billable: true,
      user_id: userId,
      travel_distance: 0
    }
  }

  getTotalSeconds() {
    const { entries } = this.state

    const timeEntries: TimeEntry[] = []

    Object
      .keys(entries)
      .forEach(rangeKey => {
        const dayKeys = Object.keys(entries[rangeKey])

        dayKeys.forEach(dayKey => {
          Object
            .values(entries[rangeKey][dayKey].entries)
            .forEach(entry => {
              timeEntries.push(entry)
            })
        })
      })

    // Calculate total amount of seconds
    return timeEntries.reduce((seconds, timeEntry) => {
      const start = moment(timeEntry.started_at)
      const end = moment(timeEntry.ended_at)
      return seconds + end.diff(start, 'seconds')
    }, 0)
  }

  onModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({ errors: {} })
  }

  syncHourInputs() {
    const { entries } = this.state
    const rangeKey = this.getRangeKey()
    const rows = this.getRows()

    rows
      .forEach((row, rowIndex) => {
        const columns = this.getSelectedRange()

        columns.forEach((column, columnIndex) => {
          const hourInput = this[this.getRefKey(rowIndex, columnIndex)]

          let inputElementValue = ''
          if (
            entries[rangeKey] &&
            entries[rangeKey][rowIndex] &&
            entries[rangeKey][rowIndex].entries &&
            entries[rangeKey][rowIndex].entries[columnIndex]
          ) {
            const timeEntry: TimeEntry = entries[rangeKey][rowIndex].entries[columnIndex]
            const start = moment(timeEntry.started_at)
            const end = moment(timeEntry.ended_at)

            // Calculate difference in minutes since seconds don't apply
            const differenceInMinutes = end.diff(start, 'minutes')

            inputElementValue = moment.duration(differenceInMinutes, 'minutes').format('HH:mm')
          }

          if (hourInput) {
            hourInput.inputElement.value = inputElementValue
          }
        })
      })
  }

  onNavigateBackward() {
    const { startDate } = this.state

    const newStartDate = moment(startDate).subtract(1, 'week').startOf('week')
    const newEndDate = moment(newStartDate).endOf('week')

    this.setState({ startDate: newStartDate, endDate: newEndDate }, this.syncHourInputs)
  }

  onNavigateForward() {
    const { startDate } = this.state

    const newStartDate = moment(startDate).add(1, 'week').startOf('week')
    const newEndDate = moment(newStartDate).endOf('week')

    this.setState({ startDate: newStartDate, endDate: newEndDate }, this.syncHourInputs)
  }

  onNavigationHeaderClick() {
    this.setState({ calendarActive: true })
  }

  onDatePopoverClose() {
    this.setState({ calendarActive: false })
  }

  onRangeChange(selectedDate: Moment) {
    this.setState({
      startDate: moment(selectedDate).startOf('week'),
      endDate: moment(selectedDate).endOf('week'),
      calendarActive: false,
    })
  }

  async onEditRowProperty(rowIndex: number, property: keyof TimeEntry, value: any, resource?: Contact | Project) {
    try {
      const { entries } = this.state
      const rangeKey = this.getRangeKey()

      // Create range key if it doesn't exist
      entries[rangeKey] = entries[rangeKey] || {}

      // Create row key if it doesn't exist
      entries[rangeKey][rowIndex] = entries[rangeKey][rowIndex] || { billable: true, entries: {} }

      // Assign row level property
      // @ts-ignore
      entries[rangeKey][rowIndex][property] = value

      if (property === 'work_type_id') {
        entries[rangeKey][rowIndex].work_type = resource
      }
      else if (property === 'contact_id') {
        entries[rangeKey][rowIndex].contact = resource
        entries[rangeKey][rowIndex].project_id = null
      } else if (property === 'project_id') {
        let billable = true

        // @ts-ignore
        if (resource && resource.price > 0) billable = false

        entries[rangeKey][rowIndex].project = resource
        entries[rangeKey][rowIndex].billable = billable
      }

      // Get column keys
      const columns = Object.keys(entries[rangeKey][rowIndex].entries)

      // Set property on entries
      columns.forEach(column => {
        // Set property
        // @ts-ignore
        entries[rangeKey][rowIndex].entries[column][property] = value

        // Reset project id if contact id changed
        if (property === 'work_type_id') {
          entries[rangeKey][rowIndex].entries[column].work_type = resource
        } else if (property === 'contact_id') {
          entries[rangeKey][rowIndex].entries[column].project = null
        } else if (property === 'project_id') {
          let billable = true
          // @ts-ignore
          if (resource && resource.price > 0) billable = false

          entries[rangeKey][rowIndex].entries[column].project = resource
          entries[rangeKey][rowIndex].entries[column].billable = billable
        }
      })

      this.setState({ entries: { ...entries } })
    } catch (ex) {
      console.error(ex)
    }
  }

  onDescriptionChange(rowIndex: number, description: string) {
    this.onEditRowProperty(rowIndex, 'description', description)
  }

  onBillableChange(rowIndex: number, billable: boolean) {
    this.onEditRowProperty(rowIndex, 'billable', billable)
  }

  onDayRateChange(rowIndex: number, dayRate: boolean) {
    this.onEditRowProperty(rowIndex, 'fixed_rate', dayRate)
  }

  async onContactChange(rowIndex: number, contactId?: string | string[], contact?: Contact) {
    this.onEditRowProperty(rowIndex, 'contact_id', contactId, contact)
  }

  onProjectChange(rowIndex: number, projectId?: string | string[], project?: Project) {
    this.onEditRowProperty(rowIndex, 'project_id', projectId, project)
  }

  onWorkTypeChange(rowIndex: number, workTypeId: string | string[], workType: WorkType) {
    this.onEditRowProperty(rowIndex, 'work_type_id', workTypeId, workType)
  }

  onTravelDistanceChange(rowIndex: number, travelDistance?: string | number) {
    this.onEditRowProperty(rowIndex, 'travel_distance', travelDistance)
  }

  onHourChange(rowIndex: number, columnIndex: number, dayMoment: Moment, event) {
    const { entries } = this.state
    const value = event.currentTarget.value
    const rangeKey = this.getRangeKey()

    if (value.length === 0 && entries[rangeKey][rowIndex].entries[columnIndex]) {
      delete entries[rangeKey][rowIndex].entries[columnIndex]
    } else if (value.length >= 1) {

      let [hour, minutes] = value.split(":")

      if (!minutes || minutes.length === '') minutes = 0
      if (minutes.length === 1) minutes = Number(minutes) * 10

      // Create range key if it doesn't exist
      entries[rangeKey] = entries[rangeKey] || {}

      // Create row key if it doesn't exist
      entries[rangeKey][rowIndex] = entries[rangeKey][rowIndex] || { billable: true, entries: {} }

      // Create column key if it doesn't exist
      entries[rangeKey][rowIndex].entries[columnIndex] = entries[rangeKey][rowIndex].entries[columnIndex] || this.getNewTimeEntry()

      const startDate = moment(dayMoment).set({ 'hour': 8, 'minute': 30 }).startOf('minute')
      const endDate = moment(startDate).add({ hour: hour, minute: minutes || 0 }).startOf('minute')

      // Set row attributes on individual time entry
      entries[rangeKey][rowIndex].entries[columnIndex].description = entries[rangeKey][rowIndex].description || ''
      entries[rangeKey][rowIndex].entries[columnIndex].contact_id = entries[rangeKey][rowIndex].contact_id || null
      entries[rangeKey][rowIndex].entries[columnIndex].contact = entries[rangeKey][rowIndex].contact || null
      entries[rangeKey][rowIndex].entries[columnIndex].project_id = entries[rangeKey][rowIndex].project_id || null
      entries[rangeKey][rowIndex].entries[columnIndex].project = entries[rangeKey][rowIndex].project || null
      entries[rangeKey][rowIndex].entries[columnIndex].work_type_id = entries[rangeKey][rowIndex].work_type_id || null
      entries[rangeKey][rowIndex].entries[columnIndex].work_type = entries[rangeKey][rowIndex].work_type || null
      entries[rangeKey][rowIndex].entries[columnIndex].billable = Boolean(entries[rangeKey][rowIndex].billable)
      entries[rangeKey][rowIndex].entries[columnIndex].fixed_rate = Boolean(entries[rangeKey][rowIndex].fixed_rate)
      entries[rangeKey][rowIndex].entries[columnIndex].travel_distance = entries[rangeKey][rowIndex].travel_distance || 0
      entries[rangeKey][rowIndex].entries[columnIndex].started_at = startDate.toISOString()
      entries[rangeKey][rowIndex].entries[columnIndex].ended_at = endDate.toISOString()
    }

    this.setState({ entries: { ...entries } })
  }

  onHourBlur() {
    this.syncHourInputs()
  }

  onDeleteRowClick(rowIndex: number) {
    const { rowCount, entries } = this.state

    // Delete values from all ranges
    Object
      // Range keys
      .keys(entries)
      .forEach(range => {
        if (entries[range]) {
          // Delete all entries at index
          delete entries[range][rowIndex]

          // Recalculate indexes bigger then row index
          Object.keys(entries[range])
            .filter(entryRowIndex => Number(entryRowIndex) > rowIndex)
            .forEach(entryRowIndex => {
              // Save current value at index
              const currentRowValue = entries[range][entryRowIndex]

              // Delete the value from the object
              delete entries[range][entryRowIndex]

              // Set at new index
              entries[range][Number(entryRowIndex) - 1] = currentRowValue
            })
        }
      })

    this.setState({
      rowCount: rowCount - 1,
      entries: { ...entries }
    }, this.syncHourInputs)
  }

  onAddRowClick() {
    const { rowCount } = this.state
    this.setState({ rowCount: rowCount + 1 })
  }

  async onFormSubmit(e) {
    e.preventDefault()
    const { entries, isSubmitting } = this.state

    if (!isSubmitting) {
      const timeEntries: TimeEntry[] = []

      try {
        this.setState({ isSubmitting: true })

        for (const rangeKey in entries) {

          if (entries[rangeKey]) {
            for (const rowKey in entries[rangeKey]) {
              const row = entries[rangeKey][rowKey]

              if (row) {
                // Get rate based on row properties (contact_id, project_id, fixed_rate)
                const rowRate = await TimeEntryHelper.getRate(row)

                if (row.entries) {
                  for (const columnKey in row.entries) {
                    const timeEntry = { ...row.entries[columnKey], rate: rowRate }

                    const responseTimeEntry = await TimeEntriesController.create(timeEntry)

                    timeEntries.push(responseTimeEntry)
                  }
                }
              }
            }
          }
        }

        if (this.props.onSubmit) this.props.onSubmit(timeEntries)
        this.props.close()
      } catch (ex) {
        console.error(ex)
      } finally {
        this.setState({ isSubmitting: false })
      }
    }
  }

  render() {
    const { t, currentUser } = this.props
    const { workspace: { setting } } = currentUser
    const { didInitialLoad, startDate, endDate, errors, entries, isSubmitting, calendarActive, hasWorkTypes } = this.state

    const rangeKey = this.getRangeKey()
    const rangeEntries = entries[rangeKey] || {}
    const rows = this.getRows()
    const selectedRange = this.getSelectedRange()

    return (
      <ModalWindow>
        <ModalHeader
          title={t('BulkTimeEntryModal::Add bulk time entries')}
          onCloseClick={this.onModalCloseClick}
        />
        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalContent onSubmit={this.onFormSubmit}>
          <ModalContentLeft>
            <ModalSubHeader>
              <FormItem style={{ padding: 24, paddingBottom: 0 }}>
                <span>{t('BulkTimeEntryModal::User')}</span>
                <ResourceCreatablePowerSelect
                  type='user'
                  value={currentUser.id}
                  isClearable={true}
                  isDisabled={true}
                />
              </FormItem>

              <TableContent>
                {rows.map((item, rowIndex) => {
                  let entryRow = entries[rangeKey] && entries[rangeKey][rowIndex]

                  return (
                    <TableRowLeft key={rowIndex}>
                      <TableRowLeftGroup>
                        <FormItem>
                          <span>{t('BulkTimeEntryModal::Description')}</span>
                          <input
                            type='text'
                            placeholder={t('BulkTimeEntryModal::Description')}
                            value={entryRow ? entryRow.description : ''}
                            onChange={(e) => this.onDescriptionChange(rowIndex, e.currentTarget.value)}
                          />
                        </FormItem>
                      </TableRowLeftGroup>

                      <TableRowLeftGroup>
                        <FormItem>
                          <span>{t('BulkTimeEntryModal::Contact')}</span>
                          <ResourceCreatablePowerSelect
                            type='contact'
                            params={{ archived: false }}
                            value={entryRow ? entryRow.contact_id : ''}
                            onChange={(contactId, contact) => this.onContactChange(rowIndex, contactId, contact)}
                            isClearable={true}
                          />
                        </FormItem>

                        <FormItem>
                          <span>{t('BulkTimeEntryModal::Project')}</span>
                          <ResourceCreatablePowerSelect
                            type='project'
                            value={entryRow ? entryRow.project_id : ''}
                            params={{ contact_id: entryRow ? entryRow.contact_id : '', 'status[in]': [ProjectStatus.ACTIVE, ProjectStatus.PROPOSAL] }}
                            createParams={{ contact_id: entryRow ? entryRow.contact_id : '' }}
                            onChange={(projectId, project) => this.onProjectChange(rowIndex, projectId, project)}
                            isClearable={true}
                            isDisabled={entryRow ? !entryRow.contact_id : true}
                          />
                        </FormItem>
                      </TableRowLeftGroup>


                      <TableRowLeftGroup>
                        <FormItem>
                          <span>{t('BulkTimeEntryModal::Billable')}</span>
                          <CheckboxInput
                            checked={entryRow ? entryRow.billable : true}
                            label={t('BulkTimeEntryModal::Billable')}
                            onChange={(checked) => {
                              this.onBillableChange(rowIndex, checked)
                            }}
                          />
                        </FormItem>

                        <FormItem>
                          <span>{t('BulkTimeEntryModal::Day rate')}</span>
                          <CheckboxInput
                            checked={entryRow ? entryRow.fixed_rate : false}
                            label={t('BulkTimeEntryModal::Day rate')}
                            onChange={(checked) => {
                              this.onDayRateChange(rowIndex, checked)
                            }}
                          />
                        </FormItem>
                      </TableRowLeftGroup>

                      <TableRowLeftGroup>
                        {hasWorkTypes && <FormItem>
                          <span>{t('BulkTimeEntryModal::Work type')}</span>
                          <ResourceCreatablePowerSelect
                            type='work_type'
                            value={entryRow?.work_type_id}
                            onChange={(workTypeId, workType) => this.onWorkTypeChange(rowIndex, workTypeId, workType)}
                            isValidNewOption={() => false}
                            isClearable={true}
                          />
                        </FormItem>}
                        <FormItem>
                          <span>{t('BulkTimeEntryModal::Travel distance')}</span>
                          <InputGroup>
                            <input
                              type='number'
                              value={entryRow && entryRow.travel_distance}
                              placeholder={t('BulkTimeEntryModal::Mileage')}
                              onChange={(e) => this.onTravelDistanceChange(rowIndex, e.currentTarget.value)}
                              min={0}
                            />
                            <span>{t('BulkTimeEntryModal::km')}</span>
                          </InputGroup>
                        </FormItem>
                      </TableRowLeftGroup>

                      <DeleteRowButton onClick={() => this.onDeleteRowClick(rowIndex)}>
                        <Icon icon='trash-alt-solid' />
                        {t('BulkTimeEntryModal::Delete row')}
                      </DeleteRowButton>
                    </TableRowLeft>
                  )
                })}
                <AddRowButton onClick={this.onAddRowClick}>
                  <Icon icon='plus' />
                  {t('BulkTimeEntryModal::Add row')}
                </AddRowButton>
              </TableContent>
            </ModalSubHeader>
          </ModalContentLeft>

          <ModalContentRight>
            <ModalContentRightHeader>
              <ModalHeaderDateNavigation>
                <ModalHeaderNavigate onClick={this.onNavigateBackward}>
                  <Icon icon='arrow-left' />
                </ModalHeaderNavigate>

                <Popover
                  activator={
                    <div onClick={this.onNavigationHeaderClick}>
                      <ModalHeaderDate>
                        {`${startDate.format(setting.date_format)} - ${endDate.format(setting.date_format)}`}
                      </ModalHeaderDate>
                    </div>
                  }
                  onClose={this.onDatePopoverClose}
                  active={calendarActive}
                >
                  <CalendarContainer>
                    <Datetime
                      value={selectedRange[0]}
                      input={false}
                      timeFormat={false}
                      open={calendarActive}
                      onChange={this.onRangeChange}
                    />
                  </CalendarContainer>
                </Popover>


                <ModalHeaderNavigate onClick={this.onNavigateForward}>
                  <Icon icon='arrow-right' />
                </ModalHeaderNavigate>
              </ModalHeaderDateNavigation>

              <ModalTableHeader>
                <ModalTableHeaderRight>
                  {selectedRange.map((dayMoment, index) => {
                    let totalDurationInSeconds = 0
                    if (rangeEntries) {
                      totalDurationInSeconds = Object
                        .values(rangeEntries)
                        .map(rangeEntry => rangeEntry?.entries[index])
                        .reduce((seconds, timeEntry) => {
                          if (timeEntry?.started_at && timeEntry?.ended_at) {
                            const start = moment(timeEntry.started_at)
                            const end = moment(timeEntry.ended_at)
                            return seconds + end.diff(start, 'seconds')
                          }
                          return seconds
                        }, 0)
                    }
                    return (
                      <DayHeader key={index}>
                        <DayHeaderWeekday>{dayMoment.format('ddd')}</DayHeaderWeekday>
                        <DayHeaderMonthDay>{`${dayMoment.format('MMM D')}`}</DayHeaderMonthDay>
                        <DayHeaderHours>{moment.duration({ 'seconds': totalDurationInSeconds }).format(`hh:mm`, { trim: false })}</DayHeaderHours>
                      </DayHeader>
                    )
                  })}
                </ModalTableHeaderRight>
              </ModalTableHeader>
            </ModalContentRightHeader>
            <ModalTableContent>
              {rows.map((row, rowIndex) => {
                let entryRow = entries[rangeKey] && entries[rangeKey][rowIndex]

                return (
                  <TableRowRight key={rowIndex}>
                    {selectedRange.map((dayMoment, columnIndex) => {
                      const timeEntry = entryRow && entryRow[columnIndex] ? entryRow[columnIndex] : null

                      return (
                        <HourInputContainer key={columnIndex}>
                          <MaskedInput
                            ref={hourInput => this[this.getRefKey(row, columnIndex)] = hourInput}
                            type='text'
                            defaultValue={timeEntry ? timeEntry.format('HH:mm') : ''}
                            placeholder='00:00'
                            guide={false}
                            showMask={true}
                            mask={[/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                            onChange={(event) => this.onHourChange(rowIndex, columnIndex, dayMoment, event)}
                            onBlur={this.onHourBlur}
                          />
                        </HourInputContainer>
                      )
                    })}
                  </TableRowRight>
                )
              })}
            </ModalTableContent>
          </ModalContentRight>
          <input type='submit' style={{ display: 'none' }} />
        </ModalContent>}

        <div className='modal-footer'>
          <div>
            <TotalHoursLabel>
              {t('BulkTimeEntryModal::Total: {{duration}}', { duration: `${TimeFormatter.durationFormat(this.getTotalSeconds(), setting.time_format)}` })}
            </TotalHoursLabel>
          </div>
          <div className='modal-footer-actions'>
            <div key='main-action' className='popover-wrapper'>
              <TooltipError
                errors={errors}
                onDismiss={this.onErrorsDismiss}
              />
              <Button
                type='success'
                text={t('BulkTimeEntryModal::Add entries')}
                onClick={this.onFormSubmit}
                isLoading={isSubmitting}
              />
            </div>
          </div>
        </div>
      </ModalWindow >
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      bulkTimeEntryModal: {
        onSubmit,
      }
    },
  } = state

  return {
    currentUser: currentUser,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps: IDispatchToProps = {
  close: closeBulkTimeEntryModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BulkTimeEntryModal))
