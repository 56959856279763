import * as React from 'react'
import Badge from '../components/Badge/Badge'
import { ContactsController, ProjectsController, TimeEntriesController, WorkTypesController } from '../controllers'
import store from '../store'
import i18n from '../translations'
import { Contact, Project, TimeEntry, WorkType } from '../types'
import moment from '../utilities/Moment'
import TimeFormatter from '../utilities/TimeFormatter'

export default class TimeEntryHelper {
	static getVariables(timeEntry: TimeEntry) {
		const setting = store.getState().authentication.currentUser.workspace.setting

		return {
			time_entry: {
				date: moment(timeEntry.started_at).format(setting.date_format).toString(),
				started_on: moment(timeEntry.started_at).format('HH:mm'),
				ended_on: moment(timeEntry.ended_at).format('HH:mm'),
				description: timeEntry.description,
				duration: TimeFormatter.durationFormat(timeEntry.duration, setting.time_format),
				travel_distance: timeEntry.travel_distance,
			},
			contact: {
				name: timeEntry.contact ? timeEntry.contact.name : '',
				alias: timeEntry.contact ? timeEntry.contact.alias : '',
			},
			project: {
				name: timeEntry.project ? timeEntry.project.name : '',
				po_number: timeEntry.project ? timeEntry.project.po_number : '',
			},
			work_type: {
				name: timeEntry?.work_type?.name || '',
				hourly_rate: timeEntry?.work_type?.hourly_rate || '',
				day_rate: timeEntry?.work_type?.day_rate || '',
			}
		}
	}
	static getBillableBadge(timeEntry: TimeEntry) {
		if (timeEntry.billable) {
			if (timeEntry.billed) {
				return <Badge type='success' text={i18n.t('TimeEntryHelper::Billed')} />
			} else {
				return <Badge type='warning' text={i18n.t('TimeEntryHelper::Unbilled')} />
			}
		}

		return <Badge type='grey' text={i18n.t('TimeEntryHelper::Unbillable')} />
	}

	static async getRate(timeEntry: TimeEntry): Promise<number> {
		const { rate } = await TimeEntriesController.getRate(timeEntry)

		return rate
	}
}