import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Playbook } from '../../types'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'
import Icon from '../Icons/Icon'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

const PlaybookContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	width: 100%;

	div[class*="-container"] {
		width: 100%;
	}

	${TaskButton} {
		height: 38px;
		margin-bottom: 0;
	}
`

interface IProps {
	activator: JSX.Element
	playbookId?: string
	onSubmit: (playbookId?: string, playbook?: Playbook) => void
	onViewPlaybookSubmission: (playbookId: string) => void
}

const PlaybookPopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator, onViewPlaybookSubmission } = props

	const [popoverActive, setPopoverActive] = React.useState(false)
	const [playbookId, setplaybookId] = React.useState(null)

	React.useEffect(() => {
		ReactTooltip.rebuild()
	})

	const onTogglePopover = () => {
		if (!popoverActive) {
			setplaybookId(props.playbookId)
		}

		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
	}

	const onPlaybookChange = (playbookId, assignee) => {
		setplaybookId(playbookId)
	}

	const onFormSubmit = () => {
		// Submit form
		props.onSubmit(playbookId)

		// Close popover
		onPopoverClose()
	}

	const onRemoveClick = () => {
		// Submit form
		props.onSubmit(null)

		// Close popover
		onPopoverClose()
	}

	const onViewPlaybookSubmissionClick = () => {
		props.onViewPlaybookSubmission(playbookId)

		onPopoverClose()
	}

	return (
		<Popover
			active={popoverActive}
			activator={
				<div onClick={onTogglePopover}>
					{activator}
				</div>
			}
			onClose={onPopoverClose}
			placement='bottom'
		>
			<TaskPopoverContainer>
				<TaskPopoverHeader>
					<TaskPopoverTitle>{t('TaskModal::Playbook')}</TaskPopoverTitle>
				</TaskPopoverHeader>
				<TaskPopoverContent>
					<PlaybookContainer>
						<ResourceCreatablePowerSelect
							type='playbook'
							value={playbookId}
							onChange={onPlaybookChange}
							placeholder={t('PlaybookPopover::Select playbook...')}
							isClearable={true}
						/>

						{playbookId && <TaskButton onClick={onViewPlaybookSubmissionClick}>
							<Icon icon='eye' />
						</TaskButton>}
					</PlaybookContainer>

					<TaskButton
						onClick={onFormSubmit}
						style={{ marginTop: 12 }}
						success
						center
					>
						{t('TaskModal::Save')}
					</TaskButton>

					<TaskButton
						onClick={onRemoveClick}
						style={{ marginTop: 8 }}
						center
					>
						{t('TaskModal::Remove')}
					</TaskButton>
				</TaskPopoverContent>
			</TaskPopoverContainer>
		</Popover >
	)
}

export default PlaybookPopover