import * as React from 'react'
import { closeCallModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { NOTES_EDITOR_CONFIG } from '../Editor/Editor'
import { Call, CurrentUser } from '../../types'
import { CallsController } from '../../controllers'
import PowerSelect from '../Form/PowerSelect'
import CallHelper from '../../helpers/CallHelper'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'

export type CallModalTab = 'details' | 'custom_fields' | 'notes' | 'schedule' | 'billing' | 'budget'

interface IStateToProps {
  call: Call
  onSubmit?: (call: Call) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeCallModal,
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  activeTab: CallModalTab
  call: Call | null
  errors: any
}

class CallModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: 'details',
      call: null,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this)
    this.onOutcomeChange = this.onOutcomeChange.bind(this)
    this.onDirectionChange = this.onDirectionChange.bind(this)
    this.onDateTimeChange = this.onDateTimeChange.bind(this)
    this.onNotesChange = this.onNotesChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  fetchForm() {
    const { call } = this.props

    CallsController
      .getForm(call.id)
      .then(response => {
        const { activeTab } = this.state
        const { statuses, call: responseCall, custom_fields } = response

        this.setState({
          call: {
            ...responseCall,
            ...call,
          },
          didInitialLoad: true,
        })
      })
      .catch(console.error)
  }

  onNavigationItemClick(e) {
    const activeTab = e.currentTarget.getAttribute('data-tab');

    this.setState({
      activeTab: activeTab
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { call } = this.state;
    const { close, onSubmit, t } = this.props

    if (call.id) {
      CallsController
        .update(call)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('CallModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('CallModal::Call successfully updated'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(error => console.error(error))
    }
    else {
      CallsController
        .create(call)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('CallModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('CallModal::Call successfully logged'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(console.error)
    }
  }

  onOutcomeChange(selectedOutcomeOption) {
    const { call } = this.state

    this.setState({
      call: {
        ...call,
        outcome: selectedOutcomeOption ? selectedOutcomeOption.value : null
      }
    })
  }

  onDirectionChange(selectedDirectionOption) {
    const { call } = this.state
    this.setState({
      call: {
        ...call,
        direction: selectedDirectionOption ? selectedDirectionOption.value : null
      }
    })
  }

  onDateTimeChange(value) {
    const { call } = this.state

    const newDatetime = moment(value)

    this.setState({
      call: {
        ...call,
        datetime: newDatetime.isValid() ? newDatetime.toISOString() : null
      }
    })
  }

  onNotesChange(notes: string) {
    const { call } = this.state;

    this.setState({
      call: {
        ...call,
        notes: notes,
      }
    });
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderNavigation() {
    const { t } = this.props
    const { activeTab } = this.state

    return (
      <ModalNavigation>
        <ModalNavigationItem active={activeTab === 'details'} data-tab='details' onClick={this.onNavigationItemClick}>
          <Icon icon='info' />
          <span>
            {t('CallModal::Details')}
          </span>
        </ModalNavigationItem>
      </ModalNavigation>
    )
  }

  renderDetails() {
    const {
      activeTab,
      call,
    } = this.state;
    const { t, currentUser } = this.props
    const { setting } = currentUser.workspace

    if (activeTab !== 'details') return null;


    const outcomeOptions = CallHelper.getOutcomeOptions()
    const selectedOutcomeOption = outcomeOptions.find(option => option.value === call?.outcome)
    const directionOptions = CallHelper.getDirectionOptions()
    const selectedDirectionOption = directionOptions.find(option => option.value === call?.direction)

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('CallModal::Contact')}<span>*</span>
              </label>
              <ResourceCreatablePowerSelect
                type='contact'
                value={call.contact_id}
                params={{ archived: false }}
                isDisabled
              />
            </div>
          </div>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('CallModal::Datetime')} <span>*</span></label>
              <DateInput
                name='datetime'
                dateFormat={setting.date_format}
                initialValue={moment(call.datetime)}
                inputProps={{ placeholder: setting.date_format }}
                onChange={this.onDateTimeChange}
                closeOnSelect
              />
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('CallModal::Direction')}</label>
              <PowerSelect
                options={directionOptions}
                value={selectedDirectionOption}
                onChange={this.onDirectionChange}
                isClearable
              />
            </div>
          </div>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('CallModal::Call outcome')}</label>
              <PowerSelect
                options={outcomeOptions}
                value={selectedOutcomeOption}
                onChange={this.onOutcomeChange}
                isClearable
              />
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-12col'>
            <EditorContainer>
              <Editor
                model={call.notes}
                onModelChange={this.onNotesChange}
                config={{
                  ...NOTES_EDITOR_CONFIG,
                  toolbarBottom: true,
                  placeholderText: t('CallModal::Add notes for this call'),
                  heightMin: 120,
                  heightMax: 265
                }}
              />
            </EditorContainer>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { call, close, t } = this.props
    const { didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={call?.id ? t('CallModal::Edit call') : t('CallModal::Log call')}
          onCloseClick={close}
          navigation={this.renderNavigation()}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper' style={{ zIndex: 99 }}>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('CallModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      callModal: {
        call,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    call: call,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeCallModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CallModal))