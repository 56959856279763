import * as React from 'react'
import Modal from './Modal'
import { AppState } from '../../store'
import { Dispatch } from 'redux';
import {
  closeContactModal,
  closeProjectModal,
  closeExpenseModal,
  closeTimeEntryExportModal,
  closeTimeEntryModal,
  closeSendLedgerItemModal,
  closePaymentDetailsModal,
  closeImportProductsModal,
  closeImportTimeEntriesModal,
  closeTaxesModal,
  closeConfirmModal,
  closeProductModal,
  closeMobileAppModal,
  closeFileModal,
  closeMoveFilesModal,
  closeImportExpensesModal,
  closeExportLedgerItemsModal,
  closeExportExpensesModal,
  closeExpenseCategoryModal,
  closeSettingsEmailTemplateModal,
  closeProjectStatusUpdateModal,
  closeExportProductsModal,
  closeCustomFieldModal,
  closeTaskModal,
  closeBoardModal,
  closeCalendarEventModal,
  closeAttachmentsViewerModal,
  closeAddAccountantModal,
  closeUserWorkspaceSettingModal,
  closeBulkTimeEntryModal,
  closeCalculateAmountModal,
  closeSendContractModal,
  closeShareLinksModal,
  closeSendProposalModal,
  closeItemsBlockSettingModal,
  closeItemBlockSettingModal,
  closeDirectionsModal,
  closeLedgerItemPaymentModal,
  closeLedgerItemTransactionHistoryModal,
  closeLedgerItemClaimModal,
  closePaymentModal,
  closePaymentInitiationModal,
  closeDealModal,
  closeDealStageModal,
  closeContentBlockTemplateModal,
  closeTaxModal,
  closeWorkTypeModal,
  closeDiscountModal,
  closePeppolParticipantModal,
  closeFinancialYearModal,
  closeTransactionModal,
  closeQuestionAndAnswerBlockSettingModal,
  closePlaybookSubmissionModal,
  closeBulkProjectStatusUpdateModal,
  closeSignatureModal,
  closeEmailViewerModal,
  closeContactGroupModal,
  closeBulkContactGroupModal,
  closeExportContactsModal,
  closeContactTypeModal,
  closeFileUploadModal,
  closePaymentQRModal,
  closeCustomComponentModal,
  closeDocumentModal,
  closeDocumentTagModal,
  closeExportDocumentsModal,
  closeUploadDocumentsModal,
  closeProductImportModal,
  closeCallModal,
  closeSendEmailModal,
  closeEmailTemplateModal,
} from '../../store/modals/actions'

import { connect } from 'react-redux';
import ContactModal from './ContactModal'
import ProjectModal from './ProjectModal'
import ProjectStatusUpdateModal from './ProjectStatusUpdateModal'
import ExpenseModal from './ExpenseModal'
import TimeEntryExportModal from './TimeEntryExportModal'
import TimeEntryModal from './TimeEntryModal'
import SendLedgerItemModal from './SendLedgerItemModal'
import PaymentDetailsModal from './PaymentDetailsModal'
import ImportProductsModal from './ImportProductsModal'
import ImportTimeEntriesModal from './ImportTimeEntriesModal'
import TaxesModal from './TaxesModal'
import ConfirmModal from './ConfirmModal'
import ProductModal from './ProductModal'
import MobileAppModal from './MobileAppModal'
import FileModal from './FileModal'
import MoveFilesModal from './MoveFilesModal';
import ImportExpensesModal from './ImportExpensesModal';
import ExportLedgerItemsModal from './ExportLedgerItemsModal';
import ExportExpensesModal from './ExportExpensesModal';
import ExpenseCategoryModal from './ExpenseCategoryModal';
import SettingsEmailTemplateModal from './SettingsEmailTemplateModal';
import ExportProductsModal from './ExportProductsModal';
import CustomFieldModal from './CustomFieldModal';
import TaskModal from './TaskModal';
import BoardModal from './BoardModal';
import CalendarEventModal from './CalendarEventModal';
import AttachmentsViewerModal from './AttachmentsViewerModal';
import AddAccountantModal from './AddAccountantModal';
import UserWorkspaceSettingModal from './UserWorkspaceSettingModal';
import BulkTimeEntrModal from './BulkTimeEntryModal';
import CalculateAmountModal from './CalculateAmountModal';
import SendContractModal from './SendContractModal';
import ShareLinksModal from './ShareLinksModal';
import SendProposalModal from './SendProposalModal';
import ItemsBlockSettingModal from './ItemsBlockSettingModal';
import ItemBlockSettingModal from './ItemBlockSettingModal';
import DirectionsModal from './DirectionsModal';
import LedgerItemPaymentModal from './LedgerItemPaymentModal';
import LedgerItemTransactionHistoryModal from './LedgerItemTransactionHistoryModal';
import LedgerItemClaimModal from './LedgerItemClaimModal';
import PaymentModal from './PaymentModal';
import PaymentInitiationModal from './PaymentInitiationModal';
import DealModal from './DealModal';
import DealStageModal from './DealStageModal';
import ContentBlockTemplateModal from './ContentBlockTemplateModal';
import TaxModal from './TaxModal';
import WorkTypeModal from './WorkTypeModal';
import DiscountModal from './DiscountModal';
import PeppolParticipantModal from './PeppolParticipantModal';
import FinancialYearModal from './FinancialYearModal';
import TransactionModal from './TransactionModal';
import QuestionAndAnswerSettingModal from './QuestionAndAnswerSettingModal';
import PlaybookSubmissionModal from './PlaybookSubmissionModal';
import BulkProjectStatusUpdateModal from './BulkProjectStatusUpdateModal';
import SignatureModal from './SignatureModal';
import EmailViewerModal from './EmailViewerModal';
import ContactGroupModal from './ContactGroupModal';
import { SignatureResult } from '../../types';
import BulkContactGroupModal from './BulkContactGroupModal';
import ExportContactsModal from './ExportContactsModal';
import ContactTypeModal from './ContactTypeModal';
import FileUploadModal from './FileUploadModal';
import PaymentQRModal from './PaymentQRModal';
import CustomComponentModal from './CustomComponentModal';
import DocumentModal from './DocumentModal';
import DocumentTagModal from './DocumentTagModal';
import ExportDocumentsModal from './ExportDocumentsModal';
import UploadDocumentsModal from './UploadDocumentsModal';
import ProductImportModal from './ProductImportModal';
import CallModal from './CallModal';
import SendEmailModal from './SendEmailModal';
import EmailTemplateModal from './EmailTemplateModal';

interface IStateToProps {
  contactModal: {
    show: boolean
  },
  projectModal: {
    show: boolean
  },
  projectStatusUpdateModal: {
    show: boolean
  },
  expenseModal: {
    show: boolean
  },
  timeEntryExportModal: {
    show: boolean
  },
  timeEntryModal: {
    show: boolean
  },
  sendLedgerItemModal: {
    show: boolean
  },
  settingsEmailTemplateModal: {
    show: boolean
  },
  paymentDetailsModal: {
    show: boolean
  }
  importProductsModal: {
    show: boolean
  },
  importTimeEntriesModal: {
    show: boolean
  },
  importExpensesModal: {
    show: boolean
  },
  taxesModal: {
    show: boolean
  },
  confirmModal: {
    show: boolean
    onCancel?: () => void
  },
  productModal: {
    show: boolean
  },
  productImportModal: {
    show: boolean
  },
  mobileAppModal: {
    show: boolean
  },
  fileModal: {
    show: boolean
  },
  moveFilesModal: {
    show: boolean
  },
  exportLedgerItemsModal: {
    show: boolean
  }
  exportExpensesModal: {
    show: boolean
  },
  expenseCategoryModal: {
    show: boolean
  },
  exportProductsModal: {
    show: boolean
  },
  customFieldModal: {
    show: boolean
  },
  boardModal: {
    show: boolean
  },
  taskModal: {
    show: boolean
  },
  calendarEventModal: {
    show: boolean
  },
  attachmentsViewerModal: {
    show: boolean
  },
  addAccountantModal: {
    show: boolean
  },
  userWorkspaceSettingModal: {
    show: boolean
  },
  bulkTimeEntryModal: {
    show: boolean
  },
  calculateAmountModal: {
    show: boolean
  },
  sendContractModal: {
    show: boolean
  },
  shareLinksModal: {
    show: boolean
  },
  sendProposalModal: {
    show: boolean
  },
  itemsBlockSettingModal: {
    show: boolean
  },
  itemBlockSettingModal: {
    show: boolean
  },
  directionsModal: {
    show: boolean
  },
  ledgerItemPaymentModal: {
    show: boolean
  },
  ledgerItemTransactionHistoryModal: {
    show: boolean
  },
  ledgerItemClaimModal: {
    show: boolean
  },
  paymentModal: {
    show: boolean
  },
  paymentInitiationModal: {
    show: boolean
  },
  dealModal: {
    show: boolean
  },
  dealStageModal: {
    show: boolean
  },
  contentBlockTemplateModal: {
    show: boolean
  },
  taxModal: {
    show: boolean
  },
  workTypeModal: {
    show: boolean
  },
  discountModal: {
    show: boolean
  },
  peppolParticipantModal: {
    show: boolean
  },
  financialYearModal: {
    show: boolean
  },
  transactionModal: {
    show: boolean
  },
  questionAndAnswerSettingModal: {
    show: boolean
  },
  playbookSubmissionModal: {
    show: boolean
  },
  bulkProjectStatusUpdateModal: {
    show: boolean
  },
  signatureModal: {
    show: boolean;
    onSubmit?: (signature: SignatureResult) => void;
  },
  emailViewerModal: {
    show: boolean
  },
  contactGroupModal: {
    show: boolean
  },
  bulkContactGroupModal: {
    show: boolean
  },
  exportContactsModal: {
    show: boolean
  },
  contactTypeModal: {
    show: boolean
  },
  fileUploadModal: {
    show: boolean
  },
  paymentQRModal: {
    show: boolean
  },
  customComponentModal: {
    show: boolean
  },
  documentModal: {
    show: boolean
  },
  documentTagModal: {
    show: boolean
  },
  exportDocumentsModal: {
    show: boolean
  },
  uploadDocumentsModal: {
    show: boolean
  },
  callModal: {
    show: boolean
  },
  sendEmailModal: {
    show: boolean
  },
  emailTemplateModal: {
    show: boolean
  }
}

interface IDispatchToProps {
  closeContactModal: typeof closeContactModal
  closeProjectModal: typeof closeProjectModal
  closeProjectStatusUpdateModal: typeof closeProjectStatusUpdateModal
  closeExpenseModal: typeof closeExpenseModal
  closeTimeEntryExportModal: typeof closeTimeEntryExportModal
  closeTimeEntryModal: typeof closeTimeEntryModal
  closeSendLedgerItemModal: typeof closeSendLedgerItemModal
  closeSettingsEmailTemplateModal: typeof closeSettingsEmailTemplateModal
  closePaymentDetailsModal: typeof closePaymentDetailsModal
  closeImportProductsModal: typeof closeImportProductsModal
  closeImportTimeEntriesModal: typeof closeImportTimeEntriesModal
  closeImportExpensesModal: typeof closeImportExpensesModal
  closeTaxesModal: typeof closeTaxesModal
  closeConfirmModal: typeof closeConfirmModal
  closeProductModal: typeof closeProductModal
  closeMobileAppModal: typeof closeMobileAppModal
  closeFileModal: typeof closeFileModal
  closeMoveFilesModal: typeof closeMoveFilesModal
  closeExportLedgerItemsModal: typeof closeExportLedgerItemsModal
  closeExportExpensesModal: typeof closeExportExpensesModal
  closeExpenseCategoryModal: typeof closeExpenseCategoryModal
  closeExportProductsModal: typeof closeExportProductsModal
  closeCustomFieldModal: typeof closeCustomFieldModal
  closeBoardModal: typeof closeBoardModal
  closeTaskModal: typeof closeTaskModal
  closeCalendarEventModal: typeof closeCalendarEventModal
  closeAttachmentsViewerModal: typeof closeAttachmentsViewerModal
  closeAddAccountantModal: typeof closeAddAccountantModal
  closeUserWorkspaceSettingModal: typeof closeUserWorkspaceSettingModal
  closeBulkTimeEntryModal: typeof closeBulkTimeEntryModal
  closeCalculateAmountModal: typeof closeCalculateAmountModal
  closeSendContractModal: typeof closeSendContractModal
  closeShareLinksModal: typeof closeShareLinksModal
  closeSendProposalModal: typeof closeSendProposalModal
  closeItemsBlockSettingModal: typeof closeItemsBlockSettingModal
  closeItemBlockSettingModal: typeof closeItemBlockSettingModal
  closeDirectionsModal: typeof closeDirectionsModal
  closeLedgerItemPaymentModal: typeof closeLedgerItemPaymentModal
  closeLedgerItemTransactionHistoryModal: typeof closeLedgerItemTransactionHistoryModal
  closeLedgerItemClaimModal: typeof closeLedgerItemClaimModal
  closePaymentModal: typeof closePaymentModal
  closePaymentInitiationModal: typeof closePaymentInitiationModal
  closeDealModal: typeof closeDealModal
  closeDealStageModal: typeof closeDealStageModal
  closeContentBlockTemplateModal: typeof closeContentBlockTemplateModal
  closeTaxModal: typeof closeTaxModal
  closeWorkTypeModal: typeof closeWorkTypeModal
  closeDiscountModal: typeof closeDiscountModal
  closePeppolParticipantModal: typeof closePeppolParticipantModal
  closeFinancialYearModal: typeof closeFinancialYearModal
  closeTransactionModal: typeof closeTransactionModal
  closeQuestionAndAnswerBlockSettingModal: typeof closeQuestionAndAnswerBlockSettingModal
  closePlaybookSubmissionModal: typeof closePlaybookSubmissionModal
  closeBulkProjectStatusUpdateModal: typeof closeBulkProjectStatusUpdateModal
  closeSignatureModal: typeof closeSignatureModal
  closeEmailViewerModal: typeof closeEmailViewerModal
  closeContactGroupModal: typeof closeContactGroupModal
  closeBulkContactGroupModal: typeof closeBulkContactGroupModal
  closeExportContactsModal: typeof closeExportContactsModal
  closeContactTypeModal: typeof closeContactTypeModal
  closeFileUploadModal: typeof closeFileUploadModal
  closePaymentQRModal: typeof closePaymentQRModal
  closeCustomComponentModal: typeof closeCustomComponentModal
  closeDocumentModal: typeof closeDocumentModal
  closeDocumentTagModal: typeof closeDocumentTagModal
  closeExportsDocumentModal: typeof closeExportDocumentsModal
  closeUploadDocumentsModal: typeof closeUploadDocumentsModal
  closeProductImportModal: typeof closeProductImportModal
  closeCallModal: typeof closeCallModal
  closeSendEmailModal: typeof closeSendEmailModal
  closeEmailTemplateModal: typeof closeEmailTemplateModal
}

type IProps = IDispatchToProps & IStateToProps

class Modals extends React.PureComponent<IProps> {
  renderContactModal() {
    const {
      contactModal: {
        show
      },
      closeContactModal
    } = this.props

    return (
      <Modal
        show={show}
        onClose={closeContactModal}
        mode='sidebar'
      >
        <ContactModal />
      </Modal>
    )
  }

  renderProjectModal() {
    const {
      projectModal: {
        show
      },
      closeProjectModal
    } = this.props

    return (
      <Modal show={show} onClose={closeProjectModal}>
        <ProjectModal />
      </Modal>
    )
  }

  renderProjectStatusUpdateModal() {
    const {
      projectStatusUpdateModal: {
        show
      },
      closeProjectStatusUpdateModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeProjectStatusUpdateModal}>
        <ProjectStatusUpdateModal />
      </Modal>
    )
  }

  renderExpenseModal() {
    const {
      expenseModal: {
        show
      },
      closeExpenseModal
    } = this.props

    return (
      <Modal
        show={show}
        onClose={closeExpenseModal}
        backgroundClassNames='dark'
        containerClassNames='dark no-transition no-padding'
      >
        <ExpenseModal />
      </Modal>
    )
  }

  renderTimeEntryExportModal() {
    const {
      timeEntryExportModal: {
        show
      },
      closeTimeEntryExportModal
    } = this.props

    return (
      <Modal show={show} onClose={closeTimeEntryExportModal}>
        <TimeEntryExportModal />
      </Modal>
    )
  }

  renderTimeEntryModal() {
    const {
      timeEntryModal: {
        show
      },
      closeTimeEntryModal
    } = this.props

    return (
      <Modal show={show} onClose={closeTimeEntryModal}>
        <TimeEntryModal />
      </Modal>
    )
  }

  renderSendLedgerItemModal() {
    const {
      sendLedgerItemModal: {
        show
      },
      closeSendLedgerItemModal
    } = this.props

    return (
      <Modal show={show} onClose={closeSendLedgerItemModal}>
        <SendLedgerItemModal />
      </Modal>
    )
  }

  renderLedgerItemEmailTemplateModal() {
    const {
      settingsEmailTemplateModal: {
        show
      },
      closeSettingsEmailTemplateModal
    } = this.props

    return (
      <Modal show={show} onClose={closeSettingsEmailTemplateModal}>
        <SettingsEmailTemplateModal />
      </Modal>
    )
  }

  renderPaymentDetailsModal() {
    const {
      paymentDetailsModal: {
        show
      },
      closePaymentDetailsModal
    } = this.props

    return (
      <Modal show={show} onClose={closePaymentDetailsModal}>
        <PaymentDetailsModal />
      </Modal>
    )
  }

  renderImportProductsModal() {
    const {
      importProductsModal: {
        show
      },
      closeImportProductsModal
    } = this.props

    return (
      <Modal show={show} onClose={closeImportProductsModal}>
        <ImportProductsModal />
      </Modal>
    )
  }

  renderImportTimeEntriesModal() {
    const {
      importTimeEntriesModal: {
        show
      },
      closeImportTimeEntriesModal
    } = this.props

    return (
      <Modal show={show} onClose={closeImportTimeEntriesModal}>
        <ImportTimeEntriesModal />
      </Modal>
    )
  }

  renderImportExpensesModal() {
    const {
      importExpensesModal: {
        show
      },
      closeImportExpensesModal
    } = this.props

    return (
      <Modal show={show} onClose={closeImportExpensesModal}>
        <ImportExpensesModal />
      </Modal>
    )
  }

  renderTaxesModal() {
    const {
      taxesModal: {
        show
      },
      closeTaxesModal
    } = this.props

    return (
      <Modal show={show} onClose={closeTaxesModal}>
        <TaxesModal />
      </Modal>
    )
  }

  renderConfirmModal() {
    const {
      confirmModal: {
        show,
        onCancel,
      },
      closeConfirmModal,
    } = this.props

    return (
      <Modal show={show} onClose={() => {
        if (onCancel) onCancel()
        closeConfirmModal()
      }}>
        <ConfirmModal />
      </Modal>
    )
  }

  renderProductModal() {
    const {
      productModal: {
        show
      },
      closeProductModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeProductModal}>
        <ProductModal />
      </Modal>
    )
  }

  renderMobileAppModal() {
    const {
      mobileAppModal: {
        show
      },
      closeMobileAppModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeMobileAppModal}>
        <MobileAppModal />
      </Modal>
    )
  }

  renderFileModal() {
    const {
      fileModal: {
        show
      },
      closeFileModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeFileModal}>
        <FileModal />
      </Modal>
    )
  }

  renderMoveFilesModal() {
    const {
      moveFilesModal: {
        show
      },
      closeMoveFilesModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeMoveFilesModal}>
        <MoveFilesModal />
      </Modal>
    )
  }

  renderExportLedgerItemsModal() {
    const {
      exportLedgerItemsModal: {
        show
      },
      closeExportLedgerItemsModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeExportLedgerItemsModal}>
        <ExportLedgerItemsModal />
      </Modal>
    )
  }

  renderExportExpensesModal() {
    const {
      exportExpensesModal: {
        show
      },
      closeExportExpensesModal
    } = this.props

    return (
      <Modal show={show} onClose={closeExportExpensesModal}>
        <ExportExpensesModal />
      </Modal>
    )
  }

  renderExpenseCategoryModal() {
    const {
      expenseCategoryModal: {
        show
      },
      closeExpenseCategoryModal
    } = this.props

    return (
      <Modal show={show} onClose={closeExpenseCategoryModal}>
        <ExpenseCategoryModal />
      </Modal>
    )
  }

  renderExportProductsModal() {
    const {
      exportProductsModal: {
        show
      },
      closeExportProductsModal
    } = this.props

    return (
      <Modal show={show} onClose={closeExportProductsModal}>
        <ExportProductsModal />
      </Modal>
    )
  }

  renderCustomFieldModal() {
    const {
      customFieldModal: {
        show
      },
      closeCustomFieldModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeCustomFieldModal}>
        <CustomFieldModal />
      </Modal>
    )
  }

  renderBoardModal() {
    const {
      boardModal: {
        show
      },
      closeBoardModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeBoardModal}>
        <BoardModal />
      </Modal>
    )
  }

  renderCalendarEvent() {
    const {
      calendarEventModal: {
        show
      },
      closeCalendarEventModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeCalendarEventModal} containerClassNames='fit-content'>
        <CalendarEventModal />
      </Modal>
    )
  }

  renderTaskModal() {
    const {
      taskModal: {
        show
      },
      closeTaskModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeTaskModal} containerClassNames='fit-content'>
        <TaskModal />
      </Modal>
    )
  }

  renderAttachmentsViewerModal() {
    const {
      attachmentsViewerModal: {
        show
      },
      closeAttachmentsViewerModal,
    } = this.props

    return (
      <Modal
        show={show}
        onClose={closeAttachmentsViewerModal}
        backgroundClassNames='dark no-transition'
        containerClassNames='dark no-transition'
      >
        <AttachmentsViewerModal />
      </Modal>
    )
  }

  renderAddAccountantModal() {
    const {
      addAccountantModal: {
        show,
      },
      closeAddAccountantModal
    } = this.props

    return (
      <Modal show={show} onClose={closeAddAccountantModal}>
        <AddAccountantModal />
      </Modal>
    )
  }

  renderUserWorkspaceSettingModal() {
    const {
      userWorkspaceSettingModal: {
        show,
      },
      closeUserWorkspaceSettingModal
    } = this.props

    return (
      <Modal show={show} onClose={closeUserWorkspaceSettingModal} containerClassNames='fit-content'>
        <UserWorkspaceSettingModal />
      </Modal>
    )
  }

  renderBulkTimeEntryModal() {
    const {
      bulkTimeEntryModal: {
        show,
      },
      closeBulkTimeEntryModal
    } = this.props

    return (
      <Modal show={show} onClose={closeBulkTimeEntryModal} containerClassNames='fit-content'>
        <BulkTimeEntrModal />
      </Modal>
    )
  }

  renderCalculateAmountModal() {
    const {
      calculateAmountModal: {
        show,
      },
      closeCalculateAmountModal
    } = this.props

    return (
      <Modal show={show} onClose={closeCalculateAmountModal}>
        <CalculateAmountModal />
      </Modal>
    )
  }

  renderSendContractModal() {
    const {
      sendContractModal: {
        show,
      },
      closeSendContractModal
    } = this.props

    return (
      <Modal show={show} onClose={closeSendContractModal}>
        <SendContractModal />
      </Modal>
    )
  }

  renderProposalModal() {
    const {
      sendProposalModal: {
        show,
      },
      closeSendProposalModal
    } = this.props

    return (
      <Modal show={show} onClose={closeSendProposalModal}>
        <SendProposalModal />
      </Modal>
    )
  }

  renderShareLinksModal() {
    const {
      shareLinksModal: {
        show,
      },
      closeShareLinksModal
    } = this.props

    return (
      <Modal show={show} onClose={closeShareLinksModal}>
        <ShareLinksModal />
      </Modal>
    )
  }

  renderSendProposalModal() {
    const {
      sendProposalModal: {
        show,
      },
      closeSendProposalModal
    } = this.props

    return (
      <Modal show={show} onClose={closeSendProposalModal}>
        <SendProposalModal />
      </Modal>
    )
  }

  renderItemsBlockSettingModal() {
    const {
      itemsBlockSettingModal: {
        show,
      },
      closeItemsBlockSettingModal
    } = this.props

    return (
      <Modal show={show} onClose={closeItemsBlockSettingModal}>
        <ItemsBlockSettingModal />
      </Modal>
    )
  }

  renderItemBlockSettingModal() {
    const {
      itemBlockSettingModal: {
        show,
      },
      closeItemBlockSettingModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeItemBlockSettingModal}>
        <ItemBlockSettingModal />
      </Modal>
    )
  }

  renderDirectionsModal() {
    const {
      directionsModal: {
        show,
      },
      closeDirectionsModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeDirectionsModal}>
        <DirectionsModal />
      </Modal>
    )
  }

  renderLedgerItemPaymentModal() {
    const {
      ledgerItemPaymentModal: {
        show,
      },
      closeLedgerItemPaymentModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeLedgerItemPaymentModal}>
        <LedgerItemPaymentModal />
      </Modal>
    )
  }

  renderLedgerItemTransactionHistoryModal() {
    const {
      ledgerItemTransactionHistoryModal: {
        show,
      },
      closeLedgerItemTransactionHistoryModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeLedgerItemTransactionHistoryModal}>
        <LedgerItemTransactionHistoryModal />
      </Modal>
    )
  }

  renderLedgerItemClaimModal() {
    const {
      ledgerItemClaimModal: {
        show,
      },
      closeLedgerItemClaimModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeLedgerItemClaimModal}>
        <LedgerItemClaimModal />
      </Modal>
    )
  }

  renderPaymentInitiationModal() {
    const {
      paymentInitiationModal: {
        show,
      },
      closePaymentInitiationModal,
    } = this.props

    return (
      <Modal show={show} onClose={closePaymentInitiationModal}>
        <PaymentInitiationModal />
      </Modal>
    )
  }

  renderPaymentModal() {
    const {
      paymentModal: {
        show,
      },
      closePaymentModal,
    } = this.props

    return (
      <Modal show={show} onClose={closePaymentModal}>
        <PaymentModal />
      </Modal>
    )
  }

  renderDealModal() {
    const {
      dealModal: {
        show,
      },
      closeDealModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeDealModal}>
        <DealModal />
      </Modal>
    )
  }

  renderDealStageModal() {
    const {
      dealStageModal: {
        show,
      },
      closeDealStageModal,
    } = this.props

    return (
      <Modal show={show} onClose={closeDealStageModal}>
        <DealStageModal />
      </Modal>
    )
  }

  renderContentBlockTemplateModal() {
    const {
      contentBlockTemplateModal: {
        show,
      },
      closeContentBlockTemplateModal
    } = this.props

    return (
      <Modal show={show} onClose={closeContentBlockTemplateModal}>
        <ContentBlockTemplateModal />
      </Modal>
    )
  }

  renderTaxModal() {
    const {
      taxModal: {
        show,
      },
      closeTaxModal
    } = this.props

    return (
      <Modal show={show} onClose={closeTaxModal}>
        <TaxModal />
      </Modal>
    )
  }

  renderWorkTypeModal() {
    const {
      workTypeModal: {
        show,
      },
      closeWorkTypeModal
    } = this.props

    return (
      <Modal show={show} onClose={closeWorkTypeModal}>
        <WorkTypeModal />
      </Modal>
    )
  }

  renderDiscountModal() {
    const {
      discountModal: {
        show,
      },
      closeDiscountModal
    } = this.props

    return (
      <Modal show={show} onClose={closeDiscountModal}>
        <DiscountModal />
      </Modal>
    )
  }

  renderPeppolParticipantModal() {
    const {
      peppolParticipantModal: {
        show,
      },
      closePeppolParticipantModal
    } = this.props

    return (
      <Modal show={show} onClose={closePeppolParticipantModal}>
        <PeppolParticipantModal />
      </Modal>
    )
  }

  renderFinancialYearModal() {
    const {
      financialYearModal: {
        show,
      },
      closeFinancialYearModal
    } = this.props

    return (
      <Modal show={show} onClose={closeFinancialYearModal}>
        <FinancialYearModal />
      </Modal>
    )
  }

  renderTransactionModal() {
    const {
      transactionModal: { show },
      closeTransactionModal
    } = this.props

    return (
      <Modal show={show} onClose={closeTransactionModal}>
        <TransactionModal />
      </Modal>
    )
  }

  renderQuestionAndAnswerBlockSettingModal() {
    const {
      questionAndAnswerSettingModal: { show },
      closeQuestionAndAnswerBlockSettingModal
    } = this.props

    return (
      <Modal show={show} onClose={closeQuestionAndAnswerBlockSettingModal}>
        <QuestionAndAnswerSettingModal />
      </Modal>
    )
  }

  renderPlaybookSubmissionModal() {
    const {
      playbookSubmissionModal: { show },
      closePlaybookSubmissionModal
    } = this.props

    return (
      <Modal show={show} onClose={closePlaybookSubmissionModal}>
        <PlaybookSubmissionModal />
      </Modal>
    )
  }

  renderBulkProjectStatusUpdateModal() {
    const {
      bulkProjectStatusUpdateModal: { show },
      closeBulkProjectStatusUpdateModal
    } = this.props

    return (
      <Modal show={show} onClose={closeBulkProjectStatusUpdateModal}>
        <BulkProjectStatusUpdateModal />
      </Modal>
    )
  }

  renderSignatureModal() {
    const {
      signatureModal: {
        show,
        onSubmit,
      },
      closeSignatureModal
    } = this.props

    return (
      <SignatureModal
        show={show}
        onSubmit={onSubmit}
        onClose={closeSignatureModal}
      />
    )
  }

  renderEmailViewerModal() {
    const {
      emailViewerModal: {
        show,
      },
      closeEmailViewerModal
    } = this.props

    return (
      <Modal show={show} onClose={closeEmailViewerModal} containerClassNames='fit-content'>
        <EmailViewerModal />
      </Modal>
    )
  }

  renderContactGroupModal() {
    const {
      contactGroupModal: {
        show,
      },
      closeContactGroupModal
    } = this.props

    return (
      <Modal show={show} onClose={closeContactGroupModal}>
        <ContactGroupModal />
      </Modal>
    )
  }

  renderBulkContactGroupModal() {
    const {
      bulkContactGroupModal: {
        show,
      },
      closeBulkContactGroupModal
    } = this.props

    return (
      <Modal show={show} onClose={closeBulkContactGroupModal}>
        <BulkContactGroupModal />
      </Modal>
    )
  }

  renderExportContactsModal() {
    const {
      exportContactsModal: {
        show
      },
      closeExportContactsModal
    } = this.props

    return (
      <Modal show={show} onClose={closeExportContactsModal}>
        <ExportContactsModal />
      </Modal>
    )
  }

  renderContactTypeModal() {
    const {
      contactTypeModal: {
        show
      },
      closeContactTypeModal
    } = this.props

    return (
      <Modal show={show} onClose={closeContactTypeModal}>
        <ContactTypeModal />
      </Modal>
    )
  }

  renderFileUploadModal() {
    const {
      fileUploadModal: {
        show
      },
      closeFileUploadModal
    } = this.props

    return (
      <Modal show={show} onClose={closeFileUploadModal}>
        <FileUploadModal />
      </Modal>
    )
  }

  renderPaymentQRModal() {
    const {
      paymentQRModal: {
        show
      },
      closePaymentQRModal
    } = this.props

    return (
      <Modal show={show} onClose={closePaymentQRModal}>
        <PaymentQRModal />
      </Modal>
    )
  }

  renderCustomComponentModal() {
    const {
      customComponentModal: {
        show
      },
      closeCustomComponentModal
    } = this.props

    return (
      <Modal show={show} onClose={closeCustomComponentModal}>
        <CustomComponentModal />
      </Modal>
    )
  }

  renderDocumentModal() {
    const {
      documentModal: { show },
      closeDocumentModal
    } = this.props

    return (
      <Modal
        show={show}
        onClose={closeDocumentModal}
        backgroundClassNames='dark'
        containerClassNames='dark no-transition no-padding'
      >
        <DocumentModal />
      </Modal>
    )
  }

  renderDocumentTagModal() {
    const {
      documentTagModal: { show },
      closeDocumentTagModal
    } = this.props

    return (
      <Modal show={show} onClose={closeDocumentTagModal}>
        <DocumentTagModal />
      </Modal>
    )
  }

  renderExportDocumentsModal() {
    const {
      exportDocumentsModal: {
        show
      },
      closeExportsDocumentModal
    } = this.props

    return (
      <Modal show={show} onClose={closeExportsDocumentModal}>
        <ExportDocumentsModal />
      </Modal>
    )
  }

  renderUploadDocumentsModal() {
    const {
      uploadDocumentsModal: {
        show
      },
      closeUploadDocumentsModal
    } = this.props

    return (
      <Modal show={show} onClose={closeUploadDocumentsModal}>
        <UploadDocumentsModal />
      </Modal>
    )
  }

  renderProductImportModal() {
    const {
      productImportModal: {
        show
      },
      closeProductImportModal
    } = this.props

    return (
      <Modal show={show} onClose={closeProductImportModal}>
        <ProductImportModal />
      </Modal>
    )
  }

  renderCallModal() {
    const {
      callModal: {
        show
      },
      closeCallModal
    } = this.props

    return (
      <Modal show={show} onClose={closeCallModal}>
        <CallModal />
      </Modal>
    )
  }

  renderSendEmailModal() {
    const {
      sendEmailModal: {
        show
      },
      closeSendEmailModal
    } = this.props

    return (
      <Modal
        show={show}
        onClose={closeSendEmailModal}
        mode='sidebar'
      >
        <SendEmailModal />
      </Modal>
    )
  }

  renderEmailTemplateModal() {
    const {
      emailTemplateModal: {
        show
      },
      closeEmailTemplateModal
    } = this.props

    return (
      <Modal
        show={show}
        onClose={closeEmailTemplateModal}
        mode='sidebar'
      >
        <EmailTemplateModal />
      </Modal>
    )
  }

  render() {
    return (
      <>

        {this.renderDealModal()}
        {this.renderDealStageModal()}
        {this.renderBulkTimeEntryModal()}
        {this.renderCalendarEvent()}
        {this.renderSendLedgerItemModal()}
        {this.renderLedgerItemEmailTemplateModal()}
        {this.renderExpenseModal()}
        {this.renderBoardModal()}
        {this.renderTaskModal()}
        {this.renderSendEmailModal()}
        {this.renderEmailTemplateModal()}
        {this.renderTimeEntryModal()}
        {this.renderProjectModal()}
        {this.renderProjectStatusUpdateModal()}
        {this.renderContactModal()}
        {this.renderImportProductsModal()}
        {this.renderImportTimeEntriesModal()}
        {this.renderImportExpensesModal()}
        {this.renderProductModal()}
        {this.renderTimeEntryExportModal()}
        {this.renderPaymentDetailsModal()}
        {this.renderTaxesModal()}
        {this.renderMobileAppModal()}
        {this.renderFileModal()}
        {this.renderMoveFilesModal()}
        {this.renderExportLedgerItemsModal()}
        {this.renderExportExpensesModal()}
        {this.renderExpenseCategoryModal()}
        {this.renderUserWorkspaceSettingModal()}
        {this.renderLedgerItemTransactionHistoryModal()}
        {this.renderLedgerItemPaymentModal()}
        {this.renderExportProductsModal()}
        {this.renderCustomFieldModal()}
        {this.renderAttachmentsViewerModal()}
        {this.renderAddAccountantModal()}
        {this.renderCalculateAmountModal()}
        {this.renderSendContractModal()}
        {this.renderProposalModal()}
        {this.renderShareLinksModal()}
        {this.renderItemsBlockSettingModal()}
        {this.renderItemBlockSettingModal()}
        {this.renderDirectionsModal()}
        {this.renderLedgerItemClaimModal()}
        {this.renderPaymentInitiationModal()}
        {this.renderPaymentModal()}
        {this.renderContentBlockTemplateModal()}
        {this.renderConfirmModal()}
        {this.renderTaxModal()}
        {this.renderWorkTypeModal()}
        {this.renderDiscountModal()}
        {this.renderPeppolParticipantModal()}
        {this.renderFinancialYearModal()}
        {this.renderTransactionModal()}
        {this.renderQuestionAndAnswerBlockSettingModal()}
        {this.renderPlaybookSubmissionModal()}
        {this.renderBulkProjectStatusUpdateModal()}
        {this.renderSignatureModal()}
        {this.renderEmailViewerModal()}
        {this.renderBulkContactGroupModal()}
        {this.renderContactGroupModal()}
        {this.renderExportContactsModal()}
        {this.renderContactTypeModal()}
        {this.renderFileUploadModal()}
        {this.renderPaymentQRModal()}
        {this.renderCustomComponentModal()}
        {this.renderDocumentModal()}
        {this.renderDocumentTagModal()}
        {this.renderExportDocumentsModal()}
        {this.renderUploadDocumentsModal()}
        {this.renderProductImportModal()}
        {this.renderCallModal()}
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    modals: {
      contactModal,
      projectModal,
      projectStatusUpdateModal,
      expenseModal,
      timeEntryExportModal,
      timeEntryModal,
      sendLedgerItemModal,
      settingsEmailTemplateModal,
      paymentDetailsModal,
      importProductsModal,
      importTimeEntriesModal,
      importExpensesModal,
      taxesModal,
      confirmModal,
      productModal,
      mobileAppModal,
      fileModal,
      moveFilesModal,
      exportLedgerItemsModal,
      exportExpensesModal,
      expenseCategoryModal,
      exportProductsModal,
      customFieldModal,
      boardModal,
      taskModal,
      calendarEventModal,
      attachmentsViewerModal,
      addAccountantModal,
      userWorkspaceSettingModal,
      bulkTimeEntryModal,
      calculateAmountModal,
      sendContractModal,
      shareLinksModal,
      sendProposalModal,
      itemsBlockSettingModal,
      itemBlockSettingModal,
      directionsModal,
      ledgerItemPaymentModal,
      ledgerItemTransactionHistoryModal,
      ledgerItemClaimModal,
      paymentModal,
      paymentInitiationModal,
      dealModal,
      dealStageModal,
      contentBlockTemplateModal,
      taxModal,
      workTypeModal,
      discountModal,
      peppolParticipantModal,
      financialYearModal,
      transactionModal,
      questionAndAnswerBlockSettingModal,
      playbookSubmissionModal,
      bulkProjectStatusUpdateModal,
      signatureModal,
      emailViewerModal,
      contactGroupModal,
      bulkContactGroupModal,
      exportContactsModal,
      contactTypeModal,
      fileUploadModal,
      paymentQRModal,
      customComponentModal,
      documentModal,
      documentTagModal,
      exportDocumentsModal,
      uploadDocumentsModal,
      productImportModal,
      callModal,
      sendEmailModal,
      emailTemplateModal,
    }
  } = state

  return {
    contactModal: contactModal,
    projectModal: projectModal,
    projectStatusUpdateModal: projectStatusUpdateModal,
    expenseModal: expenseModal,
    timeEntryExportModal: timeEntryExportModal,
    timeEntryModal: timeEntryModal,
    sendLedgerItemModal: sendLedgerItemModal,
    settingsEmailTemplateModal: settingsEmailTemplateModal,
    paymentDetailsModal: paymentDetailsModal,
    importProductsModal: importProductsModal,
    importTimeEntriesModal: importTimeEntriesModal,
    taxesModal: taxesModal,
    confirmModal: confirmModal,
    productModal: productModal,
    mobileAppModal: mobileAppModal,
    fileModal: fileModal,
    moveFilesModal: moveFilesModal,
    importExpensesModal: importExpensesModal,
    exportLedgerItemsModal: exportLedgerItemsModal,
    exportExpensesModal: exportExpensesModal,
    expenseCategoryModal: expenseCategoryModal,
    exportProductsModal: exportProductsModal,
    customFieldModal: customFieldModal,
    taskModal: taskModal,
    boardModal: boardModal,
    calendarEventModal: calendarEventModal,
    attachmentsViewerModal: attachmentsViewerModal,
    addAccountantModal: addAccountantModal,
    userWorkspaceSettingModal: userWorkspaceSettingModal,
    bulkTimeEntryModal: bulkTimeEntryModal,
    calculateAmountModal: calculateAmountModal,
    sendContractModal: sendContractModal,
    shareLinksModal: shareLinksModal,
    sendProposalModal: sendProposalModal,
    itemsBlockSettingModal: itemsBlockSettingModal,
    itemBlockSettingModal: itemBlockSettingModal,
    directionsModal: directionsModal,
    ledgerItemPaymentModal: ledgerItemPaymentModal,
    ledgerItemTransactionHistoryModal: ledgerItemTransactionHistoryModal,
    ledgerItemClaimModal: ledgerItemClaimModal,
    paymentModal: paymentModal,
    paymentInitiationModal: paymentInitiationModal,
    dealModal: dealModal,
    dealStageModal: dealStageModal,
    contentBlockTemplateModal: contentBlockTemplateModal,
    taxModal: taxModal,
    workTypeModal: workTypeModal,
    discountModal: discountModal,
    peppolParticipantModal: peppolParticipantModal,
    financialYearModal: financialYearModal,
    transactionModal: transactionModal,
    questionAndAnswerSettingModal: questionAndAnswerBlockSettingModal,
    playbookSubmissionModal: playbookSubmissionModal,
    bulkProjectStatusUpdateModal: bulkProjectStatusUpdateModal,
    signatureModal: signatureModal,
    emailViewerModal: emailViewerModal,
    contactGroupModal: contactGroupModal,
    bulkContactGroupModal: bulkContactGroupModal,
    exportContactsModal: exportContactsModal,
    contactTypeModal: contactTypeModal,
    fileUploadModal: fileUploadModal,
    paymentQRModal: paymentQRModal,
    customComponentModal: customComponentModal,
    documentModal: documentModal,
    documentTagModal: documentTagModal,
    exportDocumentsModal: exportDocumentsModal,
    uploadDocumentsModal: uploadDocumentsModal,
    productImportModal: productImportModal,
    callModal: callModal,
    sendEmailModal: sendEmailModal,
    emailTemplateModal: emailTemplateModal
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    closeContactModal: () => dispatch(closeContactModal()),
    closeProjectModal: () => dispatch(closeProjectModal()),
    closeProjectStatusUpdateModal: () => dispatch(closeProjectStatusUpdateModal()),
    closeExpenseModal: () => dispatch(closeExpenseModal()),
    closeTimeEntryExportModal: () => dispatch(closeTimeEntryExportModal()),
    closeTimeEntryModal: () => dispatch(closeTimeEntryModal()),
    closeSendLedgerItemModal: () => dispatch(closeSendLedgerItemModal()),
    closeSettingsEmailTemplateModal: () => dispatch(closeSettingsEmailTemplateModal()),
    closePaymentDetailsModal: () => dispatch(closePaymentDetailsModal()),
    closeImportProductsModal: () => dispatch(closeImportProductsModal()),
    closeImportTimeEntriesModal: () => dispatch(closeImportTimeEntriesModal()),
    closeImportExpensesModal: () => dispatch(closeImportExpensesModal()),
    closeTaxesModal: () => dispatch(closeTaxesModal()),
    closeConfirmModal: () => dispatch(closeConfirmModal()),
    closeProductModal: () => dispatch(closeProductModal()),
    closeMobileAppModal: () => dispatch(closeMobileAppModal()),
    closeFileModal: () => dispatch(closeFileModal()),
    closeMoveFilesModal: () => dispatch(closeMoveFilesModal()),
    closeExportLedgerItemsModal: () => dispatch(closeExportLedgerItemsModal()),
    closeExportExpensesModal: () => dispatch(closeExportExpensesModal()),
    closeExpenseCategoryModal: () => dispatch(closeExpenseCategoryModal()),
    closeExportProductsModal: () => dispatch(closeExportProductsModal()),
    closeCustomFieldModal: () => dispatch(closeCustomFieldModal()),
    closeBoardModal: () => dispatch(closeBoardModal()),
    closeTaskModal: () => dispatch(closeTaskModal()),
    closeCalendarEventModal: () => dispatch(closeCalendarEventModal()),
    closeAttachmentsViewerModal: () => dispatch(closeAttachmentsViewerModal()),
    closeAddAccountantModal: () => dispatch(closeAddAccountantModal()),
    closeUserWorkspaceSettingModal: () => dispatch(closeUserWorkspaceSettingModal()),
    closeBulkTimeEntryModal: () => dispatch(closeBulkTimeEntryModal()),
    closeCalculateAmountModal: () => dispatch(closeCalculateAmountModal()),
    closeSendContractModal: () => dispatch(closeSendContractModal()),
    closeShareLinksModal: () => dispatch(closeShareLinksModal()),
    closeSendProposalModal: () => dispatch(closeSendProposalModal()),
    closeItemsBlockSettingModal: () => dispatch(closeItemsBlockSettingModal()),
    closeItemBlockSettingModal: () => dispatch(closeItemBlockSettingModal()),
    closeDirectionsModal: () => dispatch(closeDirectionsModal()),
    closeLedgerItemPaymentModal: () => dispatch(closeLedgerItemPaymentModal()),
    closeLedgerItemTransactionHistoryModal: () => dispatch(closeLedgerItemTransactionHistoryModal()),
    closeLedgerItemClaimModal: () => dispatch(closeLedgerItemClaimModal()),
    closePaymentModal: () => dispatch(closePaymentModal()),
    closePaymentInitiationModal: () => dispatch(closePaymentInitiationModal()),
    closeDealModal: () => dispatch(closeDealModal()),
    closeDealStageModal: () => dispatch(closeDealStageModal()),
    closeContentBlockTemplateModal: () => dispatch(closeContentBlockTemplateModal()),
    closeTaxModal: () => dispatch(closeTaxModal()),
    closeWorkTypeModal: () => dispatch(closeWorkTypeModal()),
    closeDiscountModal: () => dispatch(closeDiscountModal()),
    closePeppolParticipantModal: () => dispatch(closePeppolParticipantModal()),
    closeFinancialYearModal: () => dispatch(closeFinancialYearModal()),
    closeTransactionModal: () => dispatch(closeTransactionModal()),
    closeQuestionAndAnswerBlockSettingModal: () => dispatch(closeQuestionAndAnswerBlockSettingModal()),
    closePlaybookSubmissionModal: () => dispatch(closePlaybookSubmissionModal()),
    closeBulkProjectStatusUpdateModal: () => dispatch(closeBulkProjectStatusUpdateModal()),
    closeSignatureModal: () => dispatch(closeSignatureModal()),
    closeEmailViewerModal: () => dispatch(closeEmailViewerModal()),
    closeContactGroupModal: () => dispatch(closeContactGroupModal()),
    closeBulkContactGroupModal: () => dispatch(closeBulkContactGroupModal()),
    closeExportContactsModal: () => dispatch(closeExportContactsModal()),
    closeContactTypeModal: () => dispatch(closeContactTypeModal()),
    closeFileUploadModal: () => dispatch(closeFileUploadModal()),
    closePaymentQRModal: () => dispatch(closePaymentQRModal()),
    closeCustomComponentModal: () => dispatch(closeCustomComponentModal()),
    closeDocumentModal: () => dispatch(closeDocumentModal()),
    closeDocumentTagModal: () => dispatch(closeDocumentTagModal()),
    closeExportsDocumentModal: () => dispatch(closeExportDocumentsModal()),
    closeUploadDocumentsModal: () => dispatch(closeUploadDocumentsModal()),
    closeProductImportModal: () => dispatch(closeProductImportModal()),
    closeCallModal: () => dispatch(closeCallModal()),
    closeSendEmailModal: () => dispatch(closeSendEmailModal()),
    closeEmailTemplateModal: () => dispatch(closeEmailTemplateModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modals)