import * as React from 'react'
import { closeProductModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { ProductsController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import MoneyInput from '../Form/MoneyInput'
import Tooltip from '../Tooltips/Tooltip'
import Button from '../Button/Button'
import styled from 'styled-components'
import NumberFormatter from '../../utilities/NumberFormatter'
import ProductHelper from '../../helpers/ProductHelper'
import Switch from '../Form/Switch'
import { withTranslation, WithTranslation } from 'react-i18next'
import CustomFieldModalInputs from '../CustomFields/CustomFieldModalInputs'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import Editor, { NOTES_EDITOR_CONFIG } from '../Editor/Editor'
import EditorContainer from '../Editor/EditorContainer'
import { CurrentUser, CustomField, Product, UserWorkspaceSettingScope, WorkspaceTax } from '../../types'
import VATHelper from '../../helpers/VatHelper'

const PhysicalSwitchContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	line-height: 0;
  font-size: 12px;
  padding: 8px 0;
  color: #858C99;
`

interface IStateToProps {
  currentUser: CurrentUser
  product: Product
  activeTab?: ProductModalTab
  supplierDisabled?: boolean
  onSubmit?: (product: Product) => void
}

interface IDispatchToProps {
  close: typeof closeProductModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

export enum ProductModalTab {
  DETAILS = 'details',
  CUSTOM_FIELDS = 'custom_fields',
  NOTES = 'notes',
  INVENTORY = 'inventory',
}

interface IState {
  didInitialLoad: boolean
  activeTab: ProductModalTab
  product: Product | null
  taxes: WorkspaceTax[]
  customFields: CustomField[]
  isSubmitting: boolean
  errors: any
}

class ProductModal extends React.Component<IProps, IState> {
  static defaultProps = {
    product: {}
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: ProductModalTab.DETAILS,
      product: null,
      taxes: [],
      customFields: [],
      isSubmitting: false,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)

    this.onNavigationItemClick = this.onNavigationItemClick.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.onPriceChange = this.onPriceChange.bind(this)
    this.onSupplierChange = this.onSupplierChange.bind(this)
    this.onPurchasePriceChange = this.onPurchasePriceChange.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onCustomFieldValueChange = this.onCustomFieldValueChange.bind(this)
    this.onTaxChange = this.onTaxChange.bind(this)
    this.onNotesChange = this.onNotesChange.bind(this)
    this.onSkuChange = this.onSkuChange.bind(this)
    this.onPhysicalChange = this.onPhysicalChange.bind(this)
    this.onBarcodeChange = this.onBarcodeChange.bind(this)
    this.onStockChange = this.onStockChange.bind(this)
    this.onMinimumStockChange = this.onMinimumStockChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onProductModalCloseClick = this.onProductModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  fetchForm() {
    const { product: propsProduct, activeTab: propActiveTab } = this.props
    const { activeTab } = this.state

    ProductsController
      .getForm({ id: propsProduct.id })
      .then(response => {
        const { product, custom_fields, taxes } = response

        let stateProduct: Product = {
          ...product,
          ...propsProduct
        }

        this.setState({
          product: { ...stateProduct },
          customFields: custom_fields,
          taxes: taxes,
          didInitialLoad: true,
          activeTab: propActiveTab ? propActiveTab : activeTab,
        })
      })
      .catch(console.error)
  }

  onNavigationItemClick(e) {
    const activeTab = e.currentTarget.getAttribute('data-tab');

    this.setState({
      activeTab: activeTab
    })
  }

  onNameChange(e) {
    const { product } = this.state;
    const name = e.currentTarget.value;

    this.setState({
      product: {
        ...product,
        name: name
      },
    })
  }


  onPriceChange(price) {
    const { product } = this.state;

    this.setState({
      product: {
        ...product,
        price: price
      }
    })
  }

  onSupplierChange(value?: string) {
    const { product } = this.state;

    this.setState({
      product: {
        ...product,
        supplier_id: value
      }
    });
  }

  onPurchasePriceChange(purchasePrice) {
    const { product } = this.state;

    this.setState({
      product: {
        ...product,
        purchase_price: purchasePrice
      }
    });
  }

  onDescriptionChange(e) {
    const { product } = this.state;

    const description = e.currentTarget.value

    this.setState({
      product: {
        ...product,
        description: description,
      }
    });
  }

  onCustomFieldValueChange(key: string, value: any) {
    const { product } = this.state

    product.custom_fields[key] = value

    this.setState({
      product: {
        ...product,
      },
    })
  }

  onTaxChange(taxCode: string) {
    const { product, taxes } = this.state

    const tax = VATHelper.getTaxRateFromCode(taxes, taxCode)

    this.setState({
      product: {
        ...product,
        tax_rate: tax?.rate || 0,
        tax_code: tax?.code || '',
      }
    })
  }

  onNotesChange(notes: string) {
    const { product } = this.state;

    this.setState({
      product: {
        ...product,
        notes: notes,
      }
    });
  }

  onSkuChange(e) {
    const { product } = this.state;
    const sku = e.currentTarget.value;

    this.setState({
      product: {
        ...product,
        sku: sku
      },
    })
  }

  onPhysicalChange(e) {
    e.preventDefault()
    const { product } = this.state

    this.setState({
      product: {
        ...product,
        physical: !product.physical
      }
    })
  }

  onBarcodeChange(e) {
    const { product } = this.state;
    const barcode = e.currentTarget.value;

    this.setState({
      product: {
        ...product,
        barcode: barcode
      },
    })
  }

  onStockChange(e) {
    const { product } = this.state;
    const stock = e.currentTarget.value;

    this.setState({
      product: {
        ...product,
        stock: stock
      },
    })
  }

  onMinimumStockChange(e) {
    const { product } = this.state;
    const minimumStock = e.currentTarget.value;

    this.setState({
      product: {
        ...product,
        minimum_stock: minimumStock
      },
    })
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { product } = this.state;
    const { close, onSubmit, t } = this.props

    this.setState({ isSubmitting: true })

    if (product.id) { // Do update
      ProductsController
        .update(product)
        .then(response => {
          const { errors, error_full_messages } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('ProductModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('ProductModal::Product succesfully updated'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(error => console.error(error))
        .finally(() => this.setState({ isSubmitting: false }))
    }
    else {
      ProductsController
        .create(product)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('ProductModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('ProductModal::Product created successfully'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(console.error)
        .finally(() => this.setState({ isSubmitting: false }))
    }
  }

  onProductModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderNavigation(): JSX.Element {
    const { t } = this.props
    const { activeTab, customFields } = this.state

    return (
      <ModalNavigation>
        <ModalNavigationItem active={activeTab === ProductModalTab.DETAILS} data-tab={ProductModalTab.DETAILS} onClick={this.onNavigationItemClick}>
          <Icon icon='info' />
          <span>
            {t('ProductModal::Details')}
          </span>
        </ModalNavigationItem>

        {customFields.length > 0 && <ModalNavigationItem active={activeTab === ProductModalTab.CUSTOM_FIELDS} data-tab={ProductModalTab.CUSTOM_FIELDS} onClick={this.onNavigationItemClick}>
          <Icon icon='magic' />
          <span>
            {t('ProductModal::Custom fields')}
          </span>
        </ModalNavigationItem>}

        <ModalNavigationItem active={activeTab === ProductModalTab.NOTES} data-tab={ProductModalTab.NOTES} onClick={this.onNavigationItemClick}>
          <Icon icon='pencil' />
          <span>
            {t('ProductModal::Notes')}
          </span>
        </ModalNavigationItem>

        <ModalNavigationItem active={activeTab === ProductModalTab.INVENTORY} data-tab={ProductModalTab.INVENTORY} onClick={this.onNavigationItemClick}>
          <Icon icon='boxes' />
          <span>
            {t('ProductModal::Stock')}
          </span>
        </ModalNavigationItem>
      </ModalNavigation>
    )
  }

  renderDetails() {
    const { currentUser: { workspace: { setting } }, supplierDisabled, t } = this.props
    const { activeTab, product, taxes } = this.state;

    if (activeTab !== ProductModalTab.DETAILS) return null;

    return (
      <div data-tab={ProductModalTab.DETAILS}>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProductModal::Name')}<span>*</span>
              </label>
              <input type='text' name='name' onChange={this.onNameChange} value={product.name} placeholder='Jane Doe' required />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProductModal::Supplier')}
              </label>

              <ResourceCreatablePowerSelect
                type='company'
                value={product.supplier_id}
                onChange={this.onSupplierChange}
                isDisabled={supplierDisabled}
                isClearable={true}
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProductModal::Price (VAT excl.)')}
                <span>*</span>
              </label>
              <MoneyInput
                name='price'
                currency={setting.default_currency}
                numberFormat={setting.number_format}
                placeholderValue={0}
                value={product.price}
                onBlur={this.onPriceChange}
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProductModal::VAT')}
              </label>
              <select
                onChange={(e) => this.onTaxChange(e.currentTarget.value)}
                value={product.tax_code}
              >
                <option value="">-</option>
                {taxes.map(tax => (<option key={tax.name} value={tax.code}>{tax.name}</option>))}
              </select>
            </div>
          </div>
        </div>

        <div className='grid'>
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PRODUCT_VIEW_PURCHASE_PRICE) && <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProductModal::Purchase price (VAT excl.)')}
              </label>

              <MoneyInput
                name='price'
                currency={setting.default_currency}
                numberFormat={setting.number_format}
                placeholderValue={0}
                value={product.purchase_price}
                onBlur={this.onPurchasePriceChange}
              />
            </div>
          </div>}

          {Number(product?.price) > 0 && Number(product?.purchase_price) > 0 && <>
            <div className='grid-cell with-3col'>
              <div className='form-item'>
                <label>{t('ProductModal::Margin')}</label>
                <input type='text' value={`${ProductHelper.getProfitMargin(product)}%`} disabled />
              </div>
            </div>

            <div className='grid-cell with-3col'>
              <div className='form-item'>
                <label>{t('ProductModal::Profit')}</label>
                <input type='text' value={`${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, ProductHelper.getProfit(product))}`} disabled />
              </div>
            </div>
          </>}
        </div>

        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('ProductModal::Description')}
                <Tooltip
                  content={t('ProductModal::The description used when importing products on quotes and invoices')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <textarea
                name='description'
                value={product.description}
                placeholder={t('ProductModal::Add a description (visible on quotations and invoices).')}
                onChange={this.onDescriptionChange}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCustomFields() {
    const { currentUser: { workspace: { setting } }, supplierDisabled, t } = this.props
    const { activeTab, product, customFields } = this.state;

    if (activeTab !== ProductModalTab.CUSTOM_FIELDS) return null;

    return (
      <div data-tab={ProductModalTab.CUSTOM_FIELDS}>
        <CustomFieldModalInputs
          customFields={customFields}
          modelCustomFields={product.custom_fields}
          onCustomFieldValueChange={this.onCustomFieldValueChange}
          onCustomFieldsChange={(customFields: CustomField[]) => this.setState({ customFields: customFields })}
        />
      </div>
    )
  }

  renderNotes() {
    const { t } = this.props
    const { activeTab, product } = this.state;

    if (activeTab !== ProductModalTab.NOTES) return null;

    return (
      <div data-tab={ProductModalTab.NOTES}>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item is-last'>
              <EditorContainer>
                <Editor
                  model={product.notes}
                  onModelChange={this.onNotesChange}
                  config={{
                    ...NOTES_EDITOR_CONFIG,
                    placeholderText: t('ProductModal::Add notes for this product (only visible to you)'),
                    heightMin: 120,
                    heightMax: 265
                  }}
                />
              </EditorContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInventory() {
    const { t } = this.props
    const { activeTab, product } = this.state;

    if (activeTab !== ProductModalTab.INVENTORY) return null;

    return (
      <div data-tab={ProductModalTab.INVENTORY}>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProductModal::SKU (Stock Keeping Unit)')}
                <Tooltip
                  content={t('ProductModal::A unique ID for internal use.')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <input type='text' name='sku' onChange={this.onSkuChange} value={product.sku} placeholder='P0001' />
              <PhysicalSwitchContainer>
                <Switch
                  name='physical'
                  label={t('ProductModal::Physical product')}
                  onClick={this.onPhysicalChange}
                  checked={product.physical}
                />
              </PhysicalSwitchContainer>
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {'Barcode (ISBN, UPC, GTIN, etc.)'}
                <Tooltip
                  content='EAN, EAN13, GTIN, GTIN13.'
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <input type='text' name='barcode' onChange={this.onBarcodeChange} value={product.barcode} placeholder='0602567412809' />
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProductModal::Stock')}
              </label>
              <input
                type='number'
                name='stock'
                min={0}
                step={1}
                onChange={this.onStockChange}
                value={product.stock}
                placeholder='10'
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProductModal::Minimum stock')}
                <Tooltip
                  content={t('ProductModal::We will send you a notification when the stock falls below the minimum stock level.')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <input
                type='number'
                name='minimum_stock'
                min={0}
                step={1}
                onChange={this.onMinimumStockChange}
                value={product.minimum_stock}
                placeholder='5'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { product, t } = this.props
    const { didInitialLoad, errors, isSubmitting } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={product.id ? t('ProductModal::Edit product') : t('ProductModal::Create product')}
          onCloseClick={this.onProductModalCloseClick}
          navigation={this.renderNavigation()}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              {this.renderCustomFields()}
              {this.renderNotes()}
              {this.renderInventory()}

              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <Button text={t('ProductModal::Save')} type='success' onClick={this.onFormSubmit} isLoading={isSubmitting} />
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      productModal: {
        product,
        activeTab,
        supplierDisabled,
        onSubmit,
      }
    }
  } = state

  return {
    product: product,
    activeTab: activeTab,
    supplierDisabled: supplierDisabled,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeProductModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductModal))