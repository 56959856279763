import * as LocalStorage from 'store'

export const enum LocalStorageKey {
  CONTACT_SORT_VALUE = 'contactSortValue',
  DEAL_SORT_VALUE = 'dealSortValue',
  PROJECT_TIMELINE_ACTIVE = 'projectTimelineActive',
  PROJECT_SORT_VALUE = 'projectSortValue',
  LEDGER_ITEM_SORT_VALUE = 'ledgerItemSortValue',
  RECURRING_INVOICE_SORT_VALUE = 'recurringInvoiceSortValue',
  EXPENSE_SORT_VALUE = 'expenseSortValue',
  PRODUCT_SORT_VALUE = 'productSortValue',
  TIME_ENTRY_SORT_VALUE = 'timeEntrySortValue',
  BOARD_LABEL_EXPANSION = 'boardLabelExpansion',
  CALENDAR_FILTER = 'calendarFilter',
  BOARD_SORT_VALUE = 'boardSortValue',
  TRACK_VIEW_LAYOUT = 'trackViewLayout',
  TRACK_VIEW_MODE = 'trackViewMode',
  LEDGER_CONDITION_SORT_VALUE = 'ledgerConditionSortValue',
  PROPOSAL_SORT_VALUE = 'proposalSortValue',
  CONTRACT_SORT_VALUE = 'contractSortValue',
  PREFERRED_EXPORT_FORMAT = 'preferredExportFormat',
  PAYMENT_SORT_VALUE = 'paymentSortValue',
  TRANSACTION_SORT_VALUE = 'transactionSortValue',
  DOCUMENTS_SORT_VALUE = 'documentsSortValue',
  IMPORT_ENTRIES_GROUP_BY = 'importEntriesGroupBy',
  IMPORT_ENTRIES_CUSTOM_DESCRIPTION = 'importEntriesCustomDescription',
  PLAYBOOK_SORT_VALUE = 'playbookSortValue',
  FILE_SORT_VALUE = 'fileSortValue'
}

export const migrateLocaleStorageValues = () => {
  if (LocalStorage.get(LocalStorageKey.RECURRING_INVOICE_SORT_VALUE) === 'recurring_schedules.next_invoice_on_asc') LocalStorage.set(LocalStorageKey.RECURRING_INVOICE_SORT_VALUE, 'recurring_schedules.next_occurrence_on_asc')
  if (LocalStorage.get(LocalStorageKey.RECURRING_INVOICE_SORT_VALUE) === 'recurring_schedules.next_invoice_on_desc') LocalStorage.set(LocalStorageKey.RECURRING_INVOICE_SORT_VALUE, 'recurring_schedules.next_occurrence_on_desc')
}

export default LocalStorage