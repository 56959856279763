import * as React from 'react'
import { closeCalendarEventModal, showContactModal, showConfirmModal, showProjectModal, showTimeEntryModal } from '../../store/modals/actions'
import MaskedInput from 'react-text-mask';
import ModalWindowPart from './Parts/ModalWindow'
import Notification from '../../utilities/Notification'
import { AppState } from '../../store';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Icon, { IconTypes } from '../Icons/Icon'
import { CalendarEventsController, TasksController } from '../../controllers';
import ModalLoader from './Parts/ModalLoader';
import { WithTranslation, withTranslation } from 'react-i18next';
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'
import Utils from '../../utilities/Utils'
import Button from '../Button/Button';
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect';
import Editor, { MINIMAL_EDITOR_CONFIG } from '../Editor/Editor';
import EditorContainer from '../Editor/EditorContainer';
import { CalendarEvent, CalendarEventAttendeeStatus, CalendarEventReminderType, CalendarEventReminderUnit, CalendarEventType, CurrentUser, CustomField, DayOfWeek, ProjectStatus, RecurrenceUnit, Task } from '../../types';
import TooltipError from '../Tooltips/ErrorTooltip';
import Map from '../Map/Map';
import MapContainer from '../Map/MapContainer';
import ReactTooltip from 'react-tooltip';
import ModalActionFollowUp from './Parts/ModalActionFollowUp';
import TaskHelper from '../../helpers/TaskHelper';

const ModalWindow = styled(ModalWindowPart)`
  width: 100%;
  max-width: 600px;
  margin-top: 48px;
  margin-bottom: 80px;
  display: block;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: initial;
    overflow-y: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const ModalTitle = styled.div`
  margin: 4px 0 0;
  padding: 8px 0 0;
  width: 100%;

  input {
    color: rgb(23, 43, 77);
    background: transparent;
    border-radius: 3px;
    box-shadow: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin: -4px -8px;
    min-height: 24px;
    padding: 4px 8px;
    resize: none;
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 2px solid transparent !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
      border-bottom: 2px solid ${Style.color.brandPrimary} !important;
    }
  }
`

const ContactProjectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    align-items: initial;
  }

  > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: ${Style.spacing.x1};

      @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        margin-right: 0;
        margin-bottom: ${Style.spacing.x1};
      }
    }

    &:last-child {
      flex: initial;
      margin-right: 0;
    }
  }
`

const EventTypes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-right: ${Style.spacing.x1};
    margin-bottom: ${Style.spacing.x0_5};
  }
`

const EventType = styled.div<{ active: boolean }>`
  line-height: 38px;
  padding: 0 ${Style.spacing.x1};
  color: #5f6368;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e8f0fe;
    color: ${Style.color.brandPrimary};
  }

  ${props => props.active && css`
    background-color: #e8f0fe;
    color: ${Style.color.brandPrimary};
  `}
`

const AllDayContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ModalClose = styled.div`
  position: absolute;
  right: -24px;
  top: 0;
`

const Dates = styled.div`
  display: flex;
  flex-direction: column;
`

const DatetimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  > div:first-child {
    width: 100%;

    > div:first-child {
      margin-right: ${Style.spacing.x0_5};
    }
  }

  > div:last-child {
    width: 100%;

    > div:first-child {
      margin-right: ${Style.spacing.x0_5};
    }
  }
`

const DatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    align-items: initial;
  }

  ${DatetimeContainer} {
    &:first-child {
      margin-right: ${Style.spacing.x1};

      @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        margin-right: 0;
      }
    }
    
  }
`

const ItemRemove = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;

  svg {
		width: 24px;
		height: 24px;
		color: #42526e;
		fill: #42526e;
	}

  &:hover {
    background-color: #f1f3f4;
  }
`

const AttendeeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  padding: ${Style.spacing.x1} 0;

  span {
    flex: 1;
  }

  ${ItemRemove} {
    margin-left: ${Style.spacing.x1};
  }
`

const AttendeeItemAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 16px;
  background-color: ${Style.color.brandWarning};
  color: white;
  border-radius: 50%;
  margin-right: ${Style.spacing.x1_5};
  font-weight: bold;
`

const ModalSection = styled.div`
  margin: 0px 40px 18px 8px;
  min-height: 32px;
  position: relative;
  display: flex;
  flex-direction: row;

  &:first-child {
    margin-top: ${Style.spacing.x1_5};
  }
`

const ModalSectionIcon = styled.div`
  display: flex;
  width: 40px;
  height: 31px;
  justify-content: center;
  align-items: center;
  margin-right: ${Style.spacing.x1_5};

  i {
    font-size: 20px;
    color: #5f6368;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #5f6368;
    color: #5f6368;
  }
`

const ModalSectionIconAction = styled(ModalSectionIcon)`
  width: 38px;
  height: 38px;
  border-radius: ${Style.variables.baseBorderRadius};
  cursor: pointer;

  &:hover {
    background: #f6f6f7;
  }
`

const InputWithBackground = styled.div`
	width: 100%;

  input, select {
    background-color: #f1f3f4 !important;
    border: 1px solid #c4cdd5 !important;
    box-shadow: none !important;
  }

  select {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIg0KCSB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjkuOTk0cHgiIGhlaWdodD0iOHB4Ig0KCSB2aWV3Qm94PSIwIDAgOS45OTQgOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOS45OTQgOCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8dGl0bGU+ZHJvcGRvd248L3RpdGxlPg0KPGRlc2NyaXB0aW9uPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjcmlwdGlvbj4NCjxnIGlkPSJCdWRnZXQiIHNrZXRjaDp0eXBlPSJNU1BhZ2UiPg0KCTxnIGlkPSJidWRnZXQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04MjIuMDAwMDAwLCAtNDU0LjAwMDAwMCkiIHNrZXRjaDp0eXBlPSJNU0FydGJvYXJkR3JvdXAiPg0KCQk8ZyBpZD0ibW9kYWxzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNzkuMDAwMDAwLCAzMDIuMDAwMDAwKSIgc2tldGNoOnR5cGU9Ik1TTGF5ZXJHcm91cCI+DQoJCQk8ZyBpZD0icHJvamVjdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDIwLjAwMDAwMCwgMC4wMDAwMDApIiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj4NCgkJCQk8ZyBpZD0iY29uZmlybWVkLV94MkJfLWRyb3Bkb3duLV94MkJfLXN0YXR1cyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQ0LjAwMDAwMCwgMTI3LjAwMDAwMCkiPg0KCQkJCQk8cGF0aCBpZD0iZHJvcGRvd24iIGQ9Ik04OC45NSwyNi4yMTFjMC4wNDYtMC4wNTgsMC4wNTgtMC4xMzksMC4wMjMtMC4yMmMtMC4wMzUtMC4wNjktMC4xMDQtMC4xMTYtMC4xODUtMC4xMTZoLTkuNTgzDQoJCQkJCQljLTAuMDgxLDAtMC4xNSwwLjA0Ni0wLjE4NSwwLjExNmMtMC4wMzUsMC4wODEtMC4wMjMsMC4xNjIsMC4wMjMsMC4yMmw0Ljc5Miw1LjgzM2MwLjAzNSwwLjA1OCwwLjEwNCwwLjA4MSwwLjE2MiwwLjA4MQ0KCQkJCQkJczAuMTE2LTAuMDIzLDAuMTYyLTAuMDgxTDg4Ljk1LDI2LjIxMXoiLz4NCgkJCQk8L2c+DQoJCQk8L2c+DQoJCTwvZz4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4NCg==);
    background-repeat: no-repeat;
    background-position: right 0.75em center;
    padding-right: ${Style.spacing.x3_5};
  }

  .fr-toolbar {
    background: #f1f3f4;
  }

  .fr-wrapper {
    background: #f1f3f4 !important;
  }

  .fr-second-toolbar {
    background: #f1f3f4;
  }
`

const ModalSectionContent = styled.div`
  width: 100%;
`

const Notifications = styled.div`
  display: flex;
  flex-direction: column;
`

const NotificationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${Style.spacing.x1};

  > div:first-child {
    margin-right: ${Style.spacing.x1};
  }

  > div:nth-child(2) {
    margin-right: ${Style.spacing.x1};
    max-width: 65px;
  }

  > div:nth-child(3) {
    margin-right: ${Style.spacing.x1};
  }
`

const AddNotificationItem = styled.div`
  display: inline-block;
  color: black;
  cursor: pointer;
  padding: ${Style.spacing.x0_5} ${Style.spacing.x1};
  border-radius: 4px;

  &:hover {
    background-color: #f1f3f4;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  calendarEvent: CalendarEvent
  contactDisabled?: boolean
  projectDisabled?: boolean
  onSubmit?: (calendarEvent: CalendarEvent) => void
  onDelete?: (calendarEventId: string) => void
}

interface IDispatchToProps {
  close: typeof closeCalendarEventModal
  showConfirmModal: typeof showConfirmModal
  showContactModal: typeof showContactModal
  showProjectModal: typeof showProjectModal
  showTimeEntryModal: typeof showTimeEntryModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  calendarEvent: CalendarEvent
  location?: string
  customFields: CustomField[]
  newAttendee: string
  errors: any
  isSubmitting: boolean
  isDeleting: boolean
  task: Task
}

class CalendarEventModal extends React.Component<IProps, IState> {
  private startTimeInput = React.createRef<MaskedInput>()
  private endTimeInput = React.createRef<MaskedInput>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      calendarEvent: null,
      location: null,
      customFields: [],
      newAttendee: '',
      errors: {},
      isSubmitting: false,
      isDeleting: false,
      task: null,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.getTypeIcon = this.getTypeIcon.bind(this)
    this.onTitleChange = this.onTitleChange.bind(this)
    this.onTypeChange = this.onTypeChange.bind(this)
    this.onContactChange = this.onContactChange.bind(this)
    this.onProjectChange = this.onProjectChange.bind(this)
    this.onAddTimeEntryClick = this.onAddTimeEntryClick.bind(this)
    this.onStartChange = this.onStartChange.bind(this)
    this.onStartTimeChange = this.onStartTimeChange.bind(this)
    this.onStartTimeBlur = this.onStartTimeBlur.bind(this)
    this.onEndChange = this.onEndChange.bind(this)
    this.onEndTimeChange = this.onEndTimeChange.bind(this)
    this.onEndTimeBlur = this.onEndTimeBlur.bind(this)
    this.onAllDayChange = this.onAllDayChange.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onFollowUpActionChange = this.onFollowUpActionChange.bind(this)
    this.onAttendeeChange = this.onAttendeeChange.bind(this)
    this.onAttendeeKeydown = this.onAttendeeKeydown.bind(this)
    this.onAttendeeRemoveClick = this.onAttendeeRemoveClick.bind(this)
    this.onLocationChange = this.onLocationChange.bind(this)
    this.onLocationBlur = this.onLocationBlur.bind(this)
    this.onNotificationItemTypeChange = this.onNotificationItemTypeChange.bind(this)
    this.onNotificationItemIntervalChange = this.onNotificationItemIntervalChange.bind(this)
    this.onNotificationItemUnitChange = this.onNotificationItemUnitChange.bind(this)
    this.onAddNotificationItemClick = this.onAddNotificationItemClick.bind(this)
    this.onCalendarEventDeleteClick = this.onCalendarEventDeleteClick.bind(this)
    this.onCalendarEventModalCloseclick = this.onCalendarEventModalCloseclick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  componentWillUnmount() { }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  async fetchForm() {
    const { calendarEvent } = this.props

    try {
      const response = await CalendarEventsController.getForm({ id: calendarEvent.id })
      const { calendar_event: responseCalendarEvent, custom_fields, contacts } = response

      this.setState({
        calendarEvent: {
          ...responseCalendarEvent,
          ...calendarEvent,
        },
        location: responseCalendarEvent?.location || null,
        customFields: custom_fields,
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onFormSubmit(e) {
    if (e) e.preventDefault()

    const { t, onSubmit, close } = this.props
    const { calendarEvent, task } = this.state;

    if (calendarEvent.start && calendarEvent.end) {
      this.setState({ isSubmitting: true })
      if (calendarEvent.id) {
        CalendarEventsController
          .update(calendarEvent)
          .then(response => {
            const { errors } = response;

            if (errors) {
              this.setState({ errors: errors });
              Notification.notifyError(t('CalendarEventModal::Oops something went wrong!'))
            }
            else {
              Notification.notifySuccess(t('CalendarEventModal::Calendar event successfully updated.'))

              if (task) {
                TasksController.create({
                  ...task,
                  name: calendarEvent?.contact ? `${task.name} ${calendarEvent.contact.name}` : task.name,
                  contact_id: calendarEvent?.contact.id,
                }).catch(console.error)
              }

              if (onSubmit) onSubmit(response)
              close()
            }
            this.setState({ isSubmitting: false })
          })
          .catch(error => console.error(error))
      } else {
        CalendarEventsController
          .create(calendarEvent)
          .then(response => {
            const { errors } = response;

            if (errors) {
              this.setState({ errors: errors });
              Notification.notifyError(t('CalendarEventModal::Oops something went wrong!'))
            }
            else {
              Notification.notifySuccess(t('CalendarEventModal::Calendar event successfully created.'))

              if (task) {
                TasksController.create({
                  ...task,
                  name: calendarEvent?.contact ? `${task.name} ${calendarEvent.contact.name}` : task.name,
                  contact_id: calendarEvent?.contact.id,
                }).catch(console.error)
              }

              if (onSubmit) onSubmit(response)
              close()
            }
            this.setState({ isSubmitting: false })
          })
          .catch(error => console.error(error))
      }
    }
  }

  onCalendarEventModalCloseclick() {
    this.props.close()
  }

  onTitleChange(e) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        title: e.currentTarget.value
      },
    })
  }

  onTypeChange(type: CalendarEventType) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        type: type
      },
    })
  }

  onContactChange(value?: string) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        contact_id: value,
        project_id: null,
      }
    })
  }

  onProjectChange(value?: string) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        project_id: value
      }
    })
  }

  onAddTimeEntryClick() {
    const { calendarEvent, } = this.state

    this.props.showTimeEntryModal({
      contactDisabled: Boolean(calendarEvent?.contact_id),
      projectDisabled: Boolean(calendarEvent?.project_id),
      timeEntry: {
        description: calendarEvent.title,
        contact_id: calendarEvent?.contact_id,
        project_id: calendarEvent?.project_id,
      },
    })
  }

  onStartChange(value) {
    const { calendarEvent } = this.state

    const newStartDatetime = moment(value)

    if (newStartDatetime.isValid()) {
      let newEndDatetime = null

      if (calendarEvent.all_day) {
        newStartDatetime.startOf('day')
        newEndDatetime = moment(newStartDatetime).endOf('day')
      }
      else if (this.startTimeInput.current) {
        // @ts-ignore
        const timeValue = this.startTimeInput.current.inputElement.value

        if (timeValue !== '' && Utils.isValidTime(timeValue)) {
          const valueParts = timeValue.split(':').filter(v => v !== '')
          const hour = valueParts[0]
          const minutes = valueParts[1]

          newStartDatetime
            .set('hour', hour)
            .set('minute', minutes)
        }
      }

      this.setState({
        calendarEvent: {
          ...calendarEvent,
          start: newStartDatetime.toISOString(),
          end: newEndDatetime ? newEndDatetime.toISOString() : calendarEvent.end,
          recurring_schedule: calendarEvent.recurring_schedule ? {
            ...calendarEvent.recurring_schedule,
            start_date: newStartDatetime.format('YYYY-MM-DD'),
          } : null
        }
      })
    }
  }

  onStartTimeChange(e) {
    const { calendarEvent } = this.state
    const value = e.currentTarget.value

    // Valid
    if (value !== '' && Utils.isValidTime(value)) {
      const valueParts = value.split(':').filter(v => v !== '')
      const hour = valueParts[0]
      const minutes = valueParts[1]

      const newStartedAt = moment(calendarEvent.start)

      if (newStartedAt.isValid()) {
        newStartedAt
          .set('hour', hour)
          .set('minutes', minutes)

        this.setState({
          calendarEvent: {
            ...calendarEvent,
            start: newStartedAt.toISOString()
          }
        })
      }
    }
  }

  onStartTimeBlur() {
    const { calendarEvent } = this.state

    const startMoment = moment(calendarEvent.start)

    if (this.startTimeInput.current && startMoment.isValid()) {
      // @ts-ignore
      this.startTimeInput.current.inputElement.value = startMoment.format('HH:mm')
    }
  }

  onEndChange(value) {
    const { calendarEvent } = this.state

    const newEndDatetime = moment(value)

    if (newEndDatetime.isValid()) {
      if (calendarEvent.all_day) {
        newEndDatetime.endOf('day')
      }
      else if (this.endTimeInput.current) {
        // @ts-ignore
        const timeValue = this.endTimeInput.current.inputElement.value
        if (timeValue !== '' && Utils.isValidTime(timeValue)) {
          const valueParts = timeValue.split(':').filter(v => v !== '')
          const hour = valueParts[0]
          const minutes = valueParts[1]

          newEndDatetime
            .set('hour', hour)
            .set('minute', minutes)
        }
      }

      if (newEndDatetime.isValid()) {
        this.setState({
          calendarEvent: {
            ...calendarEvent,
            end: newEndDatetime.toISOString(),
          }
        })
      }
    }
  }

  onEndTimeChange(e) {
    const { calendarEvent } = this.state
    const value = e.currentTarget.value

    // Valid
    if (value !== '' && Utils.isValidTime(value)) {
      const valueParts = value.split(':').filter(v => v !== '')
      const hour = valueParts[0]
      const minutes = valueParts[1]

      const newEndedAt = moment(calendarEvent.end)

      if (newEndedAt.isValid()) {
        newEndedAt
          .set('hour', hour)
          .set('minutes', minutes)

        this.setState({
          calendarEvent: {
            ...calendarEvent,
            end: newEndedAt.toISOString()
          }
        })
      }
    }
  }

  onEndTimeBlur() {
    const { calendarEvent } = this.state

    const endMoment = moment(calendarEvent.end)

    if (this.endTimeInput.current && endMoment.isValid()) {
      // @ts-ignore
      this.endTimeInput.current.inputElement.value = endMoment.format('HH:mm')
    }
  }

  onAllDayChange() {
    const { calendarEvent } = this.state

    const nowMoment = moment()
    const newAllDay = !calendarEvent.all_day
    let start = moment(calendarEvent.start)
    let end = moment(calendarEvent.end)

    // Toggled all day on, no end date should be set
    if (newAllDay) {
      start = moment(calendarEvent.start).startOf('day')
      end = moment(calendarEvent.start).endOf('day')
    } else {
      start = moment(calendarEvent.start)
        .set('hour', nowMoment.get('hour'))
        .set('minute', nowMoment.get('minute'))
        .set('seconds', 0)

      end = moment(start)
        .set('hour', start.get('hour') + 1)
        .set('minute', start.get('minute'))
        .set('seconds', 0)
    }

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        all_day: newAllDay,
        start: start.isValid() ? start.toISOString() : null,
        end: end.isValid() ? end.toISOString() : null,
      }
    })
  }

  onDescriptionChange(description: string) {
    const { calendarEvent } = this.state;

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        description: description,
      }
    });
  }

  onFollowUpActionChange(task: Task) {
    this.setState({
      task: task
    })
  }

  onAttendeeChange(e) {
    this.setState({ newAttendee: e.currentTarget.value })
  }

  onAttendeeKeydown(e) {
    const { calendarEvent, newAttendee } = this.state
    if (e.which === 13 && !e.shiftKey && Utils.isValidEmail(newAttendee)) {
      e.preventDefault();

      this.setState({
        calendarEvent: {
          ...calendarEvent,
          attendees: [
            // Remove email if exists
            ...calendarEvent.attendees.filter(attendee => attendee.email !== newAttendee),
            // Add new email
            { email: newAttendee.toLowerCase(), status: CalendarEventAttendeeStatus.TENTATIVE }
          ]
        },
        newAttendee: ''
      })
    }
  }

  onAttendeeRemoveClick(index) {
    const { calendarEvent } = this.state

    const updatedGuests = calendarEvent.attendees

    updatedGuests.splice(index, 1);

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        attendees: [...updatedGuests]
      }
    })
  }

  onLocationChange(e) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        location: e.currentTarget.value,
      }
    })
  }

  onLocationBlur() {
    const { calendarEvent } = this.state
    this.setState({ location: calendarEvent.location })
  }

  onNotificationItemTypeChange(index, e) {
    const { calendarEvent } = this.state

    const updatedReminders = calendarEvent.reminders

    updatedReminders[index].type = e.currentTarget.value

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        reminders: [...updatedReminders]
      }
    })
  }

  onNotificationItemIntervalChange(index, e) {
    const { calendarEvent } = this.state

    const updatedReminders = calendarEvent.reminders

    updatedReminders[index].interval = e.currentTarget.value

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        reminders: [...updatedReminders]
      }
    })
  }

  onNotificationItemUnitChange(index, e) {
    const { calendarEvent } = this.state

    const updatedReminders = calendarEvent.reminders

    updatedReminders[index].interval_unit = e.currentTarget.value

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        reminders: [...updatedReminders]
      }
    })
  }

  onNotificationItemRemoveClick(index) {
    const { calendarEvent } = this.state

    const updatedReminders = calendarEvent.reminders

    updatedReminders.splice(index, 1);

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        reminders: [...updatedReminders]
      }
    })
  }

  onAddNotificationItemClick(e) {
    const { calendarEvent } = this.state
    e.preventDefault()

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        reminders: [
          ...calendarEvent.reminders,
          { type: CalendarEventReminderType.NOTIFICATION, interval: 30, interval_unit: CalendarEventReminderUnit.MINUTE }
        ]
      }
    })
  }

  async onCalendarEventDeleteClick() {
    const { onDelete, close, showConfirmModal, t } = this.props
    const { calendarEvent } = this.state

    showConfirmModal({
      title: t('CalendarEventModal::Delete calendar event'),
      description: t('CalendarEventModal::You are about to delete this event. This event and all its associated will be permanently deleted. Are you sure you want to continue?'),
      action: { label: t('CalendarEventModal::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          this.setState({ isDeleting: true })
          const response = await CalendarEventsController.delete(calendarEvent.id)
          this.setState({ isDeleting: false })

          // Trigger onDelete callback
          if (onDelete) onDelete(calendarEvent.id)

          // Close modal
          close()
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  getTypeIcon(): IconTypes {
    const { calendarEvent } = this.state

    switch (calendarEvent.type) {
      case CalendarEventType.EVENT:
        return 'calendar-day'
      case CalendarEventType.APPOINTMENT:
        return 'calendar-day'
      case CalendarEventType.CALL:
        return 'phone'
      case CalendarEventType.FOCUS_TIME:
        return 'calendar-day'
      case CalendarEventType.HOLIDAY:
        return 'calendar-day'
      default:
        throw Error(`Invalid calendar event type: ${calendarEvent.type}`)
    }
  }

  render() {
    const { t, currentUser: { workspace: { setting } }, contactDisabled, projectDisabled } = this.props
    const {
      didInitialLoad,
      calendarEvent,
      location,
      newAttendee,
      errors,
      isDeleting,
      isSubmitting
    } = this.state

    const notificationTypeOptions = [
      { label: t(`CalendarEventReminderType::${CalendarEventReminderType.NOTIFICATION}`), value: CalendarEventReminderType.NOTIFICATION },
      { label: t(`CalendarEventReminderType::${CalendarEventReminderType.EMAIL}`), value: CalendarEventReminderType.EMAIL },
    ]

    return (
      <ModalWindow>
        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <form onSubmit={this.onFormSubmit}>
          <ModalSection>
            <ModalSectionIcon style={{ marginTop: 9 }}>
              <Icon icon={this.getTypeIcon()} />
            </ModalSectionIcon>

            <ModalTitle>
              <input
                type='text'
                value={calendarEvent.title}
                placeholder={t('CalendarEventModal::Enter title')}
                autoFocus={true}
                onChange={this.onTitleChange}
              />
            </ModalTitle>

            <ModalClose>
              <a href='javascript://' className='modal-close' onClick={this.onCalendarEventModalCloseclick}>
                <Icon icon='close' className='modal-close-icon' />
              </a>
            </ModalClose>
          </ModalSection>

          <ModalSection>
            <ModalSectionIcon />

            <EventTypes>
              {Object.keys(CalendarEventType).map(type => (
                <EventType
                  key={type}
                  active={String(CalendarEventType[type]) === String(calendarEvent.type)}
                  onClick={() => this.onTypeChange(CalendarEventType[type])}
                >
                  {t(`CalendarEventType::${CalendarEventType[type]}`)}
                </EventType>
              ))}
            </EventTypes>
          </ModalSection>

          <ModalSection>
            <ModalSectionIcon>
              <Icon icon='user' />
            </ModalSectionIcon>

            <ContactProjectContainer>
              <ResourceCreatablePowerSelect
                type='contact'
                params={{ archived: false }}
                value={calendarEvent.contact_id}
                onChange={this.onContactChange}
                isClearable={true}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    background: '#f1f3f4',
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    background: '#f1f3f4',
                    overflow: 'hidden'
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    background: '#f1f3f4'
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    background: '#f1f3f4'
                  }),
                  indicatorSeparator: (provided, state) => ({
                    ...provided,
                    background: '#f1f3f4'
                  }),
                }}
                isDisabled={Boolean(contactDisabled)}
              />

              <ResourceCreatablePowerSelect
                type='project'
                value={calendarEvent.project_id}
                onChange={this.onProjectChange}
                isDisabled={!calendarEvent.contact_id || Boolean(projectDisabled)}
                isClearable={true}
                params={{ 'contact_id': calendarEvent.contact_id, 'status[in]': [ProjectStatus.PROPOSAL, ProjectStatus.ACTIVE] }}
                createParams={{ contact_id: calendarEvent.contact_id }}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    background: '#f1f3f4',
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    background: '#f1f3f4',
                    overflow: 'hidden'
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    background: '#f1f3f4'
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    background: '#f1f3f4'
                  }),
                  indicatorSeparator: (provided, state) => ({
                    ...provided,
                    background: '#f1f3f4'
                  }),
                }}
              />

              <ModalSectionIconAction
                onClick={this.onAddTimeEntryClick}
                data-tip={t('CalendarEventModal::Create time entry for this event')}
              >
                <Icon icon='clock' />
              </ModalSectionIconAction>
            </ContactProjectContainer>
          </ModalSection>

          {calendarEvent && <ModalSection>
            <ModalSectionIcon>
              <Icon icon='clock' />
            </ModalSectionIcon>

            <ModalSectionContent>
              <Dates>
                <DatesContainer>
                  <DatetimeContainer>
                    <InputWithBackground style={{ marginBottom: 4 }}>
                      <DateInput
                        name='start'
                        dateFormat={setting.date_format}
                        timeFormat={false}
                        initialValue={moment(calendarEvent.start)}
                        inputProps={{ placeholder: setting.date_format }}
                        onChange={this.onStartChange}
                        closeOnSelect
                      />
                    </InputWithBackground>

                    {!calendarEvent.all_day && <InputWithBackground style={{ maxWidth: 130, marginBottom: 4, width: 'fit-content' }}>
                      <MaskedInput
                        ref={this.startTimeInput}
                        type='text'
                        defaultValue={moment(calendarEvent.start).format('HH:mm')}
                        placeholder='00:00'
                        guide={false}
                        showMask={false}
                        mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                        onChange={this.onStartTimeChange}
                        onBlur={this.onStartTimeBlur}
                        style={{ maxWidth: 70 }}
                      />
                    </InputWithBackground>}
                  </DatetimeContainer>

                  <DatetimeContainer>
                    <InputWithBackground style={{ marginBottom: 4 }}>
                      <DateInput
                        name='end'
                        dateFormat={setting.date_format}
                        timeFormat={false}
                        initialValue={moment(calendarEvent.end)}
                        inputProps={{
                          placeholder: setting.date_format
                        }}
                        onChange={this.onEndChange}
                        closeOnSelect
                      />
                    </InputWithBackground>

                    {!calendarEvent.all_day && <InputWithBackground style={{ maxWidth: 130, marginBottom: 4, width: 'fit-content' }}>
                      <MaskedInput
                        ref={this.endTimeInput}
                        type='text'
                        defaultValue={moment(calendarEvent.end).format('HH:mm')}
                        placeholder='00:00'
                        guide={false}
                        showMask={false}
                        mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                        onChange={this.onEndTimeChange}
                        onBlur={this.onEndTimeBlur}
                        style={{ maxWidth: 70 }}
                      />
                    </InputWithBackground>}
                  </DatetimeContainer>
                </DatesContainer>

                <AllDayContainer>
                  <input type='checkbox' checked={calendarEvent.all_day} onChange={this.onAllDayChange} />
                  {t('CalendarEventModal::All day')}
                </AllDayContainer>
              </Dates>
            </ModalSectionContent>
          </ModalSection>}

          <ModalSection>
            <ModalSectionIcon>
              <Icon icon='people' />
            </ModalSectionIcon>

            <ModalSectionContent>
              <InputWithBackground>
                <input
                  type='email'
                  placeholder={t('CalendarEventModal::Add attendees')}
                  value={newAttendee}
                  onChange={this.onAttendeeChange}
                  onKeyPress={this.onAttendeeKeydown}
                />
              </InputWithBackground>

              {calendarEvent?.attendees?.map((attendee, index) => {
                return (
                  <AttendeeItem key={index}>
                    <AttendeeItemAvatar>
                      {Utils.getInitials(attendee.email).toUpperCase()}
                    </AttendeeItemAvatar>

                    <span>{attendee.email}</span>

                    <ItemRemove onClick={() => this.onAttendeeRemoveClick(index)}>
                      <Icon icon='close' />
                    </ItemRemove>
                  </AttendeeItem>
                )
              })}
            </ModalSectionContent>
          </ModalSection>

          <ModalSection>
            <ModalSectionIcon>
              <Icon icon='map-marker' />
            </ModalSectionIcon>

            <ModalSectionContent>
              <InputWithBackground>
                <input
                  type='text'
                  value={calendarEvent.location}
                  placeholder={t('CalendarEventModal::Add location')}
                  onChange={this.onLocationChange}
                  onBlur={this.onLocationBlur}
                />
              </InputWithBackground>

              {location?.length > 0 && <MapContainer style={{
                marginTop: Style.spacing.x1,
                borderWidth: 1,
                borderColor: Style.color.border,
                borderStyle: 'solid'
              }}>
                <Map location={location} />
              </MapContainer>}
            </ModalSectionContent>
          </ModalSection>

          <ModalSection>
            <ModalSectionIcon>
              <Icon icon='bell' />
            </ModalSectionIcon>

            <ModalSectionContent>
              {calendarEvent.reminders && <Notifications>
                {calendarEvent.reminders.map((notification, index) => {
                  const notificationUnitOptions = [
                    { label: t(`CalendarEventReminderUnit::${CalendarEventReminderUnit.MINUTE}`, { count: notification.interval }), value: CalendarEventReminderUnit.MINUTE },
                    { label: t(`CalendarEventReminderUnit::${CalendarEventReminderUnit.HOUR}`, { count: notification.interval }), value: CalendarEventReminderUnit.HOUR },
                    { label: t(`CalendarEventReminderUnit::${CalendarEventReminderUnit.DAY}`, { count: notification.interval }), value: CalendarEventReminderUnit.DAY },
                    { label: t(`CalendarEventReminderUnit::${CalendarEventReminderUnit.WEEK}`, { count: notification.interval }), value: CalendarEventReminderUnit.WEEK },
                  ]

                  return (
                    <NotificationItem key={index}>
                      <InputWithBackground>
                        <select value={notification.type} onChange={(e) => this.onNotificationItemTypeChange(index, e)}>
                          {notificationTypeOptions.map(option => <option key={option.value} label={option.label} value={option.value} />)}
                        </select>
                      </InputWithBackground>

                      <InputWithBackground>
                        <input
                          type='number'
                          value={notification.interval}
                          min={0}
                          onChange={(e) => this.onNotificationItemIntervalChange(index, e)}
                        />
                      </InputWithBackground>

                      <InputWithBackground>
                        <select value={notification.interval_unit} onChange={(e) => this.onNotificationItemUnitChange(index, e)}>
                          {notificationUnitOptions.map(option => <option key={option.value} label={option.label} value={option.value} />)}
                        </select>
                      </InputWithBackground>

                      <ItemRemove onClick={() => this.onNotificationItemRemoveClick(index)}>
                        <Icon icon='close' />
                      </ItemRemove>
                    </NotificationItem>
                  )
                })}

              </Notifications>}

              <AddNotificationItem onClick={this.onAddNotificationItemClick}>
                {t('CalendarEventModal::Add reminder')}
              </AddNotificationItem>
            </ModalSectionContent>
          </ModalSection>

          <ModalSection>
            <ModalSectionIcon>
              <Icon icon='align-justify' />
            </ModalSectionIcon>

            <ModalSectionContent>
              <InputWithBackground>
                <EditorContainer>
                  <Editor
                    model={calendarEvent.description}
                    onModelChange={this.onDescriptionChange}
                    config={{
                      ...MINIMAL_EDITOR_CONFIG,
                      placeholderText: t('CalendarEventModal::Add a more detailed description'),
                      heightMin: 120,
                      heightMax: 265
                    }}
                  />
                </EditorContainer>
              </InputWithBackground>
            </ModalSectionContent>
          </ModalSection>

          <div className='modal-footer'>
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <Button
                  text={t('CalendarEventModal::Save')}
                  type='success'
                  onClick={this.onFormSubmit}
                  isLoading={isSubmitting}
                />
                <input type='submit' style={{ display: 'none' }} />
              </div>
              <ModalActionFollowUp
                onFollowUpActionChange={this.onFollowUpActionChange}
              />
            </div>
            <div>
              {calendarEvent.id && <Button
                text={t('CalendarEventModal::Delete')}
                type='danger'
                onClick={this.onCalendarEventDeleteClick}
                isLoading={isDeleting}
              />}
            </div>
          </div>
        </form>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      calendarEventModal: {
        calendarEvent,
        contactDisabled,
        projectDisabled,
        onSubmit,
        onDelete,
      }
    },
    timer: {
      entry
    },
  } = state

  return {
    currentUser: currentUser,
    calendarEvent: calendarEvent,
    contactDisabled: contactDisabled,
    projectDisabled: projectDisabled,
    onSubmit: onSubmit,
    onDelete: onDelete,
  }
}

const mapDispatchToProps: IDispatchToProps = {
  close: closeCalendarEventModal,
  showConfirmModal: showConfirmModal,
  showContactModal: showContactModal,
  showProjectModal: showProjectModal,
  showTimeEntryModal: showTimeEntryModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CalendarEventModal))
